import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import { withTranslation, useTranslation } from 'react-i18next';
import { STATUSES } from 'containers/Dashboard/statuses';

const chevronDown = require('../../assets/images/chevron-down.svg');
const chevronUp = require('../../assets/images/chevron-up.svg');

class CustomToggle extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.props.onClick(e);
  }

  render() {
    return (
      <a href="#help" style={{ textDecoration: 'none' }} onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}

const StatusDropdown = ({ step, setStep }) => {
  const { t } = useTranslation();

  const onSelectOption = (leadStatus, label) => {
    setStep(leadStatus);
  };

  function renderTitle(status) {
    switch (status) {
      case STATUSES.SALE:
        return t('leadsStatusSuccessful');
      case STATUSES.NO_SALE:
        return t('leadsStatusFailed');
      default:
        return t('leadsStatusCompleted');
    }
  }
  return (
    <Dropdown drop="down" className="status-select-dropdown">
      <Dropdown.Toggle
        childBsPrefix={classNames('take-full-width status-dropdown-btn', {
          successful: step === STATUSES.SALE,
          failed: step === STATUSES.NO_SALE,
        })}
        id="dropdown-basic"
      >
        <span>
          <span className="number">3.</span>
          {renderTitle(step)}
        </span>
        <SvgLoader path={chevronDown}>
          <SvgProxy selector=".down-arrow" fill={step === null ? 'initial' : '#fff'} />
        </SvgLoader>
      </Dropdown.Toggle>

      <Dropdown.Menu className="status-select-dropdown-menu">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="title clickable">
            <span>
              <span className="number">3.</span>
              {/* {t('leadsStatusCompleted')} */}
              {renderTitle(step)}
            </span>
            <img src={chevronUp} className="chevron-down" alt="Chevron down src" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Item
          onClick={() => onSelectOption(STATUSES.SALE)}
          className="status-dropdown-item success clickable"
        >
          <span className="icon-Vectortick-mark" />
          {t('leadsStatusSuccessful')}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => onSelectOption(STATUSES.NO_SALE)}
          className="status-dropdown-item last error clickable"
        >
          <span className="icon-cross-mark" />
          {t('leadsStatusFailed')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default StatusDropdown;
