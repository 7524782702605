import React, { useState } from 'react';
import classNames from 'classnames';
import './CurrencySelect.scss';

function CurrencySelect({ onChange }) {
  const [selectedTarif, onSelectTarif] = useState('usd');

  function handleChange(curr) {
    onSelectTarif(curr);
    onChange(curr);
  }

  return (
    <div className="CurrencySelect">
      <button
        className={classNames('btn', {
          selected: selectedTarif === 'usd',
          'not-selected': selectedTarif !== 'usd',
        })}
        onClick={() => handleChange('usd')}
      >
        USD
      </button>
      <button
        className={classNames('btn', {
          selected: selectedTarif === 'uah',
          'not-selected': selectedTarif !== 'uah',
        })}
        onClick={() => handleChange('uah')}
      >
        UAH
      </button>
      <button
        className={classNames('btn', {
          selected: selectedTarif === 'rub',
          'not-selected': selectedTarif !== 'rub',
        })}
        onClick={() => handleChange('rub')}
      >
        RUB
      </button>
    </div>
  );
}

export default CurrencySelect;
