import React, { useState } from 'react';
import CheckEmail from './CheckEmail/CheckEmail';
import ResetPasswordForm from './ResetPasswordForm/ResetPasswordForm';
import { useTranslation } from 'react-i18next';

import './ResetPassword.scss';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    currentForm: 'email',
    email: '',
  });

  const changeForm = (name, email) => {
    setForm({ currentForm: name, email });
  };

  return (
    <div className="reset-password-page">
      {form.currentForm !== 'email' ? (
        <CheckEmail email={form.email} type="reset" />
      ) : (
        <>
          <ResetPasswordForm changeForm={changeForm} />
        </>
      )}
    </div>
  );
};

export default ResetPassword;
