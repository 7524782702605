import React, { Component } from 'react';
import { Button, Form, Col } from 'react-bootstrap';
import OculisaTextField from './OculisaTextField';
import { withTranslation } from 'react-i18next';
import './ContactUs.scss';
import { toast } from 'react-toastify';
import { contactUs } from 'api';

class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      message: '',
    };
  }

  handleClick = async () => {
    const { name, email, message } = this.state;
    if (!name || !email || !message) {
      toast.error('Enter fields');
      return;
    }

    try {
      await contactUs({
        contactName: name,
        contactEmail: email,
        contactMessage: message,
      });

      window.dataLayer.push({ event: 'contact-us'});
      toast.success('Success!');
      this.props.close();
    } catch (error) {
      console.dir(error);
      toast.error(JSON.stringify(error));
    }
  };
  render() {
    return (
      <div className="contact-us">
        <h4 className="modal-title">{this.props.t('contactUs')}</h4>

        <Form>
          <Form.Row>
            <Form.Group as={Col} sm={12} md={6} lg={6}>
              <OculisaTextField
                label={this.props.t('name')}
                value={this.state.name}
                onChange={(e) => this.setState({ name: e })}
              />
            </Form.Group>

            <Form.Group as={Col} sm={12} md={6} lg={6}>
              <OculisaTextField
                label={this.props.t('contactEmail')}
                value={this.state.email}
                onChange={(e) => this.setState({ email: e })}
              />
            </Form.Group>
          </Form.Row>
          <Form.Group>
            <Form.Control
              as="textarea"
              placeholder={this.props.t('question')}
              value={this.state.question}
              onChange={(e) => this.setState({ message: e.target.value })}
              style={{ height: 154 }}
              className="oculisa-textfield textarea"
            />
          </Form.Group>
          <Form.Group style={{ textAlign: 'center', marginBottom: 0 }}>
            <Button size="lg" className="purple-button modal-submit-btn" onClick={this.handleClick}>
              <h3 className="modal-submit no-margin-bottom">{this.props.t('submit')}</h3>
            </Button>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default withTranslation()(ContactUs);
