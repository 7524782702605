import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import BasicSettings from '../NewSite/BasicSettings';
import TechnicalSettings from '../NewSite/TechnicalSettings';
import CodeSnippet from '../NewSite/CodeSnippet';
import { useTranslation, initReactI18next } from 'react-i18next';

import * as api from 'api';
import * as siteApi from 'api/site';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { validate } from '../NewSite/c';

const closeIcon = require('../../assets/images/close-icon.svg');

function EditSiteModal({ show, handleClose, id }) {
  const { t } = useTranslation();

  const [form, setForm] = useState({
    customForms: [],
  });

  const [contries, setContries] = useState([]);
  const [languages, setLanguages] = useState([]);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    getContries();
    getLanguages();
    getWebsite(id);
  }, []);

  async function getContries() {
    try {
      const response = await api.getContries();
      setContries(response.data.result.items);
    } catch (error) {
      console.dir(error);
    }
  }

  async function getLanguages() {
    try {
      const response = await api.getLanguages();
      setLanguages(response.data.result.items);
    } catch (error) {
      console.dir(error);
    }
  }

  async function getWebsite(id) {
    try {
      const response = await siteApi.getWebsite(id);

      response.data.data.customForms.push({
        trigger: '',
        form: ''
      });
      setForm(response.data.data);
    } catch (error) {
      console.dir(error);
      toast.error(JSON.stringify(error));
    }
  }

  function handleChange(value, name) {
    if (['supportLanguages', 'operatingCountries'].includes(name)) {
    }
    setForm({
      ...form,
      [name]: value,
    });
  }

  async function save() {
    if (!validate(form, setErrors)) {
      return;
    }

    try {
      const data = { ...form ,  customForms: form.customForms.filter(({ form, trigger }) => {
        return form && trigger;
      })};
      delete data.siteDomain;
      const response = await siteApi.updateWebsite(id, data);
      toast.success('Success!');
      handleClose();
    } catch (error) {
      console.dir(error);

      if (isEmpty(error.response.data.errors)) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
        setErrors(error.response.data.errors);
      }
    }
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      backdropClassName="custom-modal-backdrop"
      dialogClassName="edit-site-modal"
    >
      <Modal.Header>
        <p className="section-header-text">{t('editSite')}</p>
        <div className="close-button-container" onClick={handleClose}>
          <img src={closeIcon} alt="Close modal icon" />
        </div>
      </Modal.Header>
      <Modal.Body className="edit-site-content">
        <a className="site-title" href={`//${form.siteDomain}`} target="_blank">
          {form.siteDomain}
        </a>
        <div className="content">
          <div className="row sections-container">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="section-header-wrapper first">
                <p className="section-step-title">{t('newsiteBasic')}</p>
              </div>
              <BasicSettings
                editModal
                errors={errors}
                siteDomain={form.siteDomain}
                targetCustomer={form.targetCustomer}
                supportLanguages={form.supportLanguages}
                operatingCountries={form.operatingCountries}
                handleChange={handleChange}
                contries={contries}
                languages={languages}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="section-header-wrapper">
                <p className="section-step-title">{t('techSettings')}</p>
                <p className="optional-text">{t('optional')}</p>
              </div>
              <TechnicalSettings
                editModal
                trackedForms={form.trackedForms}
                errors={errors}
                unTrackedForms={form.unTrackedForms}
                interactiveEvents={form.interactiveEvents}
                customForms={
                  isEmpty(form.customForms)
                    ? [
                        {
                          form: '',
                          trigger: '',
                        },
                      ]
                    : form.customForms
                } // delete
                cartSettings={form.cartSettings}
                handleChange={handleChange}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="section-header-wrapper">
                <p className="section-step-title">{t('newsiteCodeInstall')}</p>
              </div>
              <CodeSnippet editModal apiKey={form.apiKey} website={form} />
              <div className="save-btn-container save-edit">
                <button onClick={save} className="btn purple-button" id="edit-website-gtm">
                  {t('save')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default EditSiteModal;
