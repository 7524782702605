import React, { useState, useEffect, useContext } from 'react';
import BasicSettings from './BasicSettings';
import TechnicalSettings from './TechnicalSettings';
import CodeSnippet from './CodeSnippet';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button } from 'components/Common';
import { ShepherdTourContext } from 'react-shepherd';

import * as api from 'api';
import * as siteApi from 'api/site';
import { isEmpty } from 'lodash';

import { validate } from './c';

const CreateNewSite = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    supportLanguages: [],
    operatingCountries: [],
    targetCustomer: 'final_customers',
    customForms: [
      {
        form: '',
        trigger: '',
      },
    ],
    // cartSettings: '[{ "trackInfo": "Hello World"}]',
    // trackedForms: '1234',
    // unTrackedForms: '.untracked-forms',
    // interactiveEvents: 'click, double-click',
    // siteDomain: 'xyz.com',
  });

  const tour = useContext(ShepherdTourContext);

  const [contries, setContries] = useState([]);
  const [errors, setErrors] = useState({});
  const [languages, setLanguages] = useState([]);
  const [apiKey, setApiKey] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getContries();
    getLanguages();

    try {
      const tutorial = JSON.parse(localStorage.getItem('tutorial'));

      if (!(tutorial && tutorial.connectSite)) {
        tour.start();
        localStorage.setItem('tutorial', JSON.stringify({ ...tutorial, connectSite: true }));
      }
    } catch (error) {}
  }, [tour]);

  async function getContries() {
    try {
      const response = await api.getContries();
      setContries(response.data.result.items);
    } catch (error) {
      console.dir(error);
    }
  }

  async function getLanguages() {
    try {
      const response = await api.getLanguages();
      setLanguages(response.data.result.items);
    } catch (error) {
      console.dir(error);
    }
  }

  function handleChange(value, name, i) {
    if (['supportLanguages', 'operatingCountries'].includes(name)) {
    }
    setForm({
      ...form,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: null,
    });
  }

  async function save() {
    if (!validate(form, setErrors)) {
      return;
    }

    setLoading(true);
    try {
      const response = await siteApi.createWebsite({
        ...form,
        siteDomain: form.siteDomain.toLowerCase(),
        customForms: form.customForms.filter(({ form, trigger }) => {
          return form && trigger;
        }),
      });

      toast.success('Success!');

      setApiKey(response.data.data); // TODO: temp
    } catch (error) {
      console.dir(error);
      if (isEmpty(error.response.data.errors)) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
        setErrors(error.response.data.errors);
      }
    }
    setLoading(false);
  }

  if (apiKey) {
    return (
      <div className="container create-new-site">
        <div className="content">
          <p className="section-header-text">{t('newsiteTitle')}</p>
          <div className="row sections-container">
            <div className="step-title col-sm-12 col-md-12 col-lg-3">
              <p className="section-step-title">{t('newsiteCodeInstall')}</p>
              <p className="step-number">3</p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-9">
              <CodeSnippet website={apiKey} />
              <div className="save-btn-container">
                <Button to="/personal-cabinet" onClick={save} className="btn purple-button">
                  {t('back')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container create-new-site">
      <div className="content">
        <p className="section-header-text">{t('newsiteTitle')}</p>
        <div className="row sections-container">
          <div className="step-title col-sm-12 col-md-12 col-lg-3">
            <p className="section-step-title">{t('newsiteBasic')}</p>
            <p className="step-number">1</p>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-9">
            <BasicSettings
              siteDomain={form.siteDomain}
              targetCustomer={form.targetCustomer}
              errors={errors}
              supportLanguages={form.supportLanguages}
              operatingCountries={form.operatingCountries}
              handleChange={handleChange}
              contries={contries}
              languages={languages}
            />
          </div>
        </div>
        <div className="row sections-container">
          <div className="step-title col-sm-12 col-md-12 col-lg-3">
            <div className="section-step-title-block">
              <p className="section-step-title">{t('newsiteTechnical')}</p>
              <p className="optional-text">{t('optional')}</p>
            </div>
            <p className="step-number">2</p>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-9">
            <TechnicalSettings
              trackedForms={form.trackedForms}
              unTrackedForms={form.unTrackedForms}
              interactiveEvents={form.interactiveEvents}
              customForms={form.customForms}
              cartSettings={form.cartSettings}
              errors={{}}
              handleChange={handleChange}
            />
          </div>

          <div className="col-sm-12 col-md-12 col-lg-9">
            <div className="save-btn-container">
              <Button
                disabled={isLoading}
                onClick={save}
                id="create-website-gtm"
                className="btn purple-button"
              >
                {t('save')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewSite;
