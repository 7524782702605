export const lang = {
  en: {
    translation: {
      product: 'Product',
      pricing: 'Pricing',
      getStarted: 'Get Started',
      requestAnIntegration: 'Request an integration',
      logIn: 'Login',
      landingMainTitle: 'Focus on more valuable leads',
      landingMainDescription: 'Boost your sales with new generation lead management product',
      getLeadsFree: 'Get 5 leads for free',
      сonnectDescription: 'Connect with your site in less than 3 minutes.',
      noCredit: 'No credit card required.',
      landingUser1: 'Graduated from Stanford',
      landingUser2: 'Marketer at Microsoft',
      landingUser3: 'From San Jose, CA',
      landingUser4: 'Married',
      landingUser5: 'Possibility of deal: 43%',
      landingUser6: 'Ordered an iPhone',
      enrichLeads: 'Enrich your leads with accurate data',
      scoreLeads: 'Score your leads with AI',
      driveROI: 'Drive the ROI',
      exploreEnrich: 'gather 60+ implicit and explicit factors for each lead',
      exploreScore: 'and manage them in real-time ranking table',
      exploreDrive: 'increase your sales conversion and close more deals with personalized sales',
      exploreAll: 'Explore all features',
      howItWorks: 'How it works',
      connect: 'Connect',
      expand: 'Expand',
      predict: 'Predict',
      manage: 'Manage',
      getNotified: 'Get notified',
      howConnect: 'Easily connect your site with our product',
      howExpand: 'Your leads data automatically expands up to 60+ factors',
      howPredict: 'Our AI gives a deal prediction, lead score and sales recommendations',
      howManage: 'Manage your leads in a real-time ranking table',
      howNotified: 'Get email-notifications with full sales information for new leads',
      readyBoostSales: 'Ready to boost your sales?',
      getStartedLessMinutes: 'Get started in less than 3 minutes',
      email: 'Your email',
      alreadyHaveAccount: 'Already have an account?',
      termsOfService: 'Terms of Service',
      privacyPolicy: 'Privacy Policy',
      contactUs: 'Contact Us',
      productTour: 'Product Tour',
      productTitle: 'The eyes of your sales department',
      productDescription: 'Oculisa can see what humans do not see – Big Data',
      analiticsTitle: 'Deep analytics for each lead',
      analiticsDescription:
        'Real people send requests from your site. Oculisa helps you to analyze online behavior of your leads.',
      analiticsFirstVisit: 'First visit date',
      analiticsFirstVisitDescription: 'Find out when your potential client first visited your site',
      analiticsEndVisit: 'Visit end time',
      analiticsEndVisitDescription: 'Determine at what time lead left your site',
      analiticsCreationRequest: 'Creation time of the request',
      analiticsCreationRequestDescription:
        'Find out how long it takes a potential client to leave a request',
      analiticsBrowserInfo: 'Browser Info',
      analiticsBrowserInfoDescription: 'Determine which browser your potential client uses',
      analiticsDevice: 'Device (OS, language, dimensions)',
      analiticsDeviceDescription:
        'Determine from which device the potential client viewed your site',
      analiticsLocation: 'Location',
      analiticsLocationDescription: 'Determine from which location the request was sent',
      analiticsPagesHistory: 'Pages History',
      analiticsPagesHistoryDescription: 'Offer your client only products which he is interested in',
      analiticsTraffic: 'Traffic Source',
      analiticsTrafficDescription: 'Determine the most effective advertising channels',
      analiticsVisits: 'Visits (total, today, page)',
      analiticsVisitsDescription: 'Analyze your potential clients engagement',
      analiticsTime: 'Time Spent (total, today, page)',
      analiticsTimeDescription: 'Determine the degree of interest of your potential client',
      analiticsEvents: 'Interaction Events',
      analiticsEventsDescription:
        'Find out the elements with which the lead interacted on the site',
      analiticsShopping: 'Shopping Cart',
      analiticsShoppingDescription: 'Actualize your potential clients purchases',

      internalData: 'Internal data',
      websiteVisitDate: 'Website visit date',
      additionalBrowserInfo: 'Additional browserm information',
      osDevice: 'OS/Device',
      deviceLanguage: 'Device/Browser language',
      mobileVersion: 'Mobile version',
      screenResolution: 'Screen resolution on load',
      browserVersion: 'Browser version',
      countryVisitor: 'Country of the visitor',
      subregion: 'Subregion',
      timeZone: 'Time Zone',
      submittedPage: 'Submitted page URL',
      submittedPageTitle: 'Title of the submitted page',
      websiteName: 'Qualified name of the website',
      reffer: 'Referrer',
      totalSiteVisits: 'Total site visits',
      todaySiteVisits: 'Today site visits',
      todaySubmittedVisits: 'Today submitted page visits',
      totalTimeSpent: 'Total time spent (ms)',
      todayTimeSpent: 'Today time spent (ms)',
      todaySubmittedTimeSpent: 'Today submitted page time spent (ms)',
      htmlIdForm: 'HTML id of the form',
      htmlNameForm: 'HTML name of the form',
      numberOfInputs: 'Number of inputs',
      userTrustZone: 'Submitted page belongs to the user trust zone',
      interactionEvents: 'List of the interaction events clicks',
      reccomendedProducts: 'Recommended products',
      outherFactors: 'other important implicit factors',
      identifyLeadsTitle: 'Identify your leads',
      identifyLeadsDescription: 'Know more than just contacts of your leads.',
      identifyLeadsDescription2: 'Our algorithms will find accurate data to enrich your prospects.',
      identifyPhoto: 'Photo',
      identifyPhotoDescription: 'Get a real photo of your potential client without meeting',
      identifyContacts: 'Contacts',
      identifyContactsDescription: 'Get actual contacts of your lead',
      identifyName: 'Full name',
      identifyNameDescription: 'Identify your prospect’s full name',
      identifyPosition: 'Position',
      identifyPositionDescription: 'Find out the current position of your lead',
      identifyAddress: 'Address',
      identifyAddressDescription: 'Determine the actual physical address of your lead',
      identifyEducation: 'Education',
      identifyEducationDescription: 'Find out what education your potential client has',
      identifyBirthday: 'Birthday Date',
      identifyBirthdayDescription: 'Congratulate your lead happy birthday on time',
      identifyGroups: 'Groups subscribed',
      identifyGroupsDescription:
        'Find out which groups your lead is subscribed to in social networks',
      identifyGender: 'Gender',
      identifyGenderDescription: 'Accurately determine the gender of your potential client',
      identifyExperience: 'Experience',
      identifyExperienceDescription: 'Find out what experience your potential client has',
      identifyStatus: 'Marital Status',
      identifyStatusDescription: 'Find out your leads marital status',
      identifyChildren: 'Number of children',
      identifyChildrenDescription: 'Find out how many children your potential client has',
      identifySubscribers: 'Number of subscribers',
      identifySubscribersDescription: 'Find out how popular your lead in social networks',
      identifyInterests: 'Interests',
      identifyInterestsDescription:
        'Find out what your potential client is interested in besides your product',
      identifyTweets: 'Latest tweets',
      identifyTweetsDescription: 'Find out what your lead tweets',
      identifyLanguages: 'Languages',
      identifyLanguagesDescription: 'Determine what languages your potential client speaks',
      empowerSalesAi: 'Empower your sales with Artificial Intelligence',
      or: 'or',
      empowerSalesAiDescription:
        'With our model salespeople focus on the right leads and maximize the company’s revenue.',
      empowerSalesScoring: 'Predictive Lead Scoring',
      empowerSalesScoringDescription:
        'Focus on more valuable leads thanks to AI predictive scoring',
      empowerSalesForecasting: 'Forecasting of a deal probability',
      empowerSalesForecastingDescription:
        'Find out the probability of a successful deal before contacting a potential client',
      empowerAdvices: 'Upsell Advices',
      empowerAdvicesDescription:
        'Sell more thanks to the personal product recommendations of our AI',
      manageLeads: 'Manage your leads in easy-to-use sales funnel page',
      manageLeadsDescription:
        'Ranking table updates in real-time to optimize your time on searching the right prospect to close a deal.',
      priceTitle: 'A simple price that fits your business',
      priceDescription: 'No hidden fees. No credit card required. No long-term commitments.',
      priceChooseTariff: 'Choose your tariff',
      tariffs: 'Tariffs',
      priceStartup: 'Startup',
      priceGrowth: 'Growth',
      priceEnterprise: 'Enterprise',
      priceCorporate: 'Corporate',
      priceUpTo: 'up to',
      priceFrom: 'from',
      priceLeads: 'leads',
      pricePerLead: 'per lead',
      priceSave: 'save',
      priceStarted: 'Get Started',
      priceIndividual: 'Contact us',
      priceTarrif: 'Each tariff has full features inside',
      pricePaidLeads: 'Paid leads do not burn at the end of the month',
      priceLess: 'The more you purchase – the less you pay',
      name: 'Your name',
      contactEmail: 'Contact email',
      domainName: 'Domain name of the target site',
      requestAverageAmount: 'An average amount of leads per month:',
      requestLess: 'Less than',
      requestMore: 'More than',
      requestComments: 'Comments (which contact forms should be tracked, any else information):',
      submit: 'Submit',
      yourPassword: 'Your password',
      signUp: 'Sign up',
      question: 'Question',
      settings: 'Settings',
      paymentBalance: 'Payment and balance',
      logout: 'Logout',
      dashboardTitle: 'Connected sites',
      dashboardConnectButton: 'Connect a new site',
      dashboardManage: 'Manage leads',
      dashboardEdit: 'Edit',
      profileSettings: 'Profile Settings',
      profileMainSettings: 'Main settings',
      profileChangePasswordTitle: 'Change Password',
      profileChangePasswordDescription: '(optional)',
      profileChangePasswordButton: 'Change Password',
      save: 'Save',
      profileNameDescription: 'Enter your name so we get to know you better',
      profileEmailDescription: 'Enter your email to receive service notifications.',
      profileNotification: 'Notification language',
      english: 'English',
      russian: 'Russian',
      profileOldPass: 'Enter old password',
      profileOldPassPlaceholder: 'Old password',
      profileNewPass: 'Enter new password',
      profileNewPassPlaceholder: 'New password',
      profileRepeatPass: 'Repeat new password',
      profileRepeatPassPlaceholder: 'Confirm new password',
      forgotPassword: 'I forgot password',
      paymentCurrentBalance: 'Current balance',
      leads: 'leads',
      paymentPricingInform: 'Pricing Information',
      paymnetPurchaseLeads: 'Purchase leads',
      paymentSelectText: 'Select the number of requests required:',
      paymentAmount: 'Payment amount:',
      currency: '$',
      paymentTarrif: 'Applicable tariff:',
      paymentComplete: 'Complete payment',
      newsiteTitle: 'Connect a new site',
      newsiteBasic: 'Basic settings',
      newsiteTechnical: 'Technical settings',
      optional: '(optional)',
      newsiteCodeInstall: 'Install the code on the site',
      siteDomain: 'Site domain',
      siteClients: 'Your clients are',
      siteCustomers: 'Final Customers',
      siteOther: 'Other businesses',
      siteSelectLanguage: 'Select the languages in which your customer support is available',
      siteSelectCountries: 'Select the countries in which you operate',
      techSettings: 'Technical settings instructions',
      techTrackForms: 'Track only specific forms',
      techTrackFormsDescription: 'By default, it tracks all the forms on site.',
      techTrackFormsPlaceholder:
        'Enter the specific CSS classes of the forms that you want to track separated by the coma.',
      techIgnoreForms: 'Ignore specific forms',
      techIgnoreFormsPlaceholder:
        'Enter the specific CSS classes of the forms that you want to ignore tracking separated by the coma.',
      techEvents: 'Interaction events',
      techEventsPlaceholder:
        'For example: [ { tag: "a", contains: "download", events : [\'click\']} ]',
      techCustomForms: 'Custom forms',
      techShoppingCartDescription:
        'Only applicable for e-commerce. Request a free integration in case of any difficulties.',
      techShoppingCartPlaceholder: 'For example',
      techCustomFormsDescription:
        'Used for the forms that don’t follow the regular <form> and <input type=’submit’> approach.',
      techFormID: 'Form ID:',
      techFormTrigger: 'Form trigger:',
      techShoppingCartText: 'Shopping Cart Settings',
      techInstallBeforeTitle: 'Before the closing tag </body> insert the code:',
      copyCode: 'Copy code',
      sendCodeAndInstructionsOnEmail: 'Send code and instructions on email',
      editSite: 'Edit site settings',
      leadsDashboard: 'leads dashboard',
      leadsStatusPending: 'Pending',
      leadsStatusProcessing: 'Processing',
      leadsStatusCompleted: 'Completed',
      leadsStatusSuccessful: 'Complete',
      leadsStatusFailed: 'Failed',
      leadDealProbability: 'Deal probability:',
      leadName: 'Name',
      leadPhone: 'Phone',
      leadEmail: 'Email',
      leadAdress: 'Adress',
      leadPosition: 'Position',
      leadViewProfile: 'View Profile',
      leadDelete: 'Delete',
      leadStatus: 'Status:',
      comment: 'Comment',
      dashboardLeadsStatusCompleted: 'Completed',
      back: 'Back',
      lead: 'Lead',
      profile: 'Profile',
      externalData: 'External data',
      contactsAdditional: 'Additional contacts',
      twitterSubs: 'Twitter Followers',
      signUpTitile: 'Youre 3 minutes away',
      signUpTitleDescription: 'from boosting your sales!',
      confirmPass: 'confirm password',
      signUpDescription: 'By clicking on “Get 5 leads for free”, you agree to our',
      signUpDescription2: '. Learn how we collect, use and share your data in our',
      signUpDescription3:
        '. You may receive marketing emails along with product updates and can opt out any time.',
      signUpTerms: 'Terms of Service',
      signUpPolicy: 'Privacy Policy',
      password: 'password',
      resetPassword: 'Reset password',
      reset: 'Reset',
      update: 'Update',
      passwordForgot: 'Forgot password?',
      passwordShort: 'Short password',
      fieldRequired: 'This field is required',
      passwordsShouldMatch: 'Passwords should match',
      cookieText: 'By using this website you agree to our ',
      iAgree: 'Accept',
      cookiePolicy: 'privacy policy',
      emailVerified: 'email verified',
      emailUnverified: 'email unverified',
      tutorialButtonNext: 'Next',
      tutorialButtonBack: 'Back',
      tutorialButtonExit: 'Exit',
      tutorialConnectSiteButton:
        'Get started with Oculisa by connecting your website to track leads.',
      tutorialTechicalSettings:
        'Here you can specify optional settings for tracking leads (for example, for tracking the shopping cart of an eCommerce website or tracking specific contact forms on the website). If you have any difficulties - see our instructions or contact us for a free integration.',
      tutorialBasicSettings:
        "The settings in this block are required for Oculisa to work successfully on most websites. Only four questions that won't take even a couple of minutes.",
    },
  },
  ru: {
    translation: {
      tutorialConnectSiteButton:
        'Начните использование Oculisa, подключив новый сайт для отслеживания заявок.',
      tutorialButtonNext: 'Продолжить',
      tutorialButtonBack: 'Назад',
      tutorialButtonExit: 'Закончить',
      tutorialTechicalSettings:
        'Здесь Вы можете указать опциональные настройки для отслеживания заявок (например, для отслеживания корзины интернет-магазина или отслеживания определенных контактных форм на сайте). Если у Вас возникли любые трудности - просмотрите нашу инструкцию или обратитесь к нам за бесплатной интеграцией.',
      tutorialBasicSettings:
        'Настройки в этом блоке обязательные для успешной работы Oculisa на большинстве сайтов. Всего 4 вопроса, которые не займут и пары минут.',
      product: 'Продукт',
      pricing: 'Цены',
      or: 'или',
      getStarted: 'Установить на сайт',
      requestAnIntegration: 'Запросить интеграцию',
      logIn: 'Войти',
      landingMainTitle: 'Начните видеть клиента насквозь',
      landingMainDescription:
        'Фокусируйтесь на более ценных клиентах, прогнозируйте исход сделки и увеличивайте продажи',
      getLeadsFree: 'Получить 5 лидов бесплатно',
      сonnectDescription: 'Лёгкое подключение.',
      noCredit: 'Кредитная карта не требуется.',
      landingUser1: 'Окончила Мгу',
      landingUser2: 'Маркетолог в Microsoft',
      landingUser3: 'Живёт в Киеве',
      landingUser4: 'Замужем',
      landingUser5: 'Вероятность Сделки: 43%',
      landingUser6: 'Заказал Iphone',
      enrichLeads: 'Расширяйте данные о потенциальных клиентах',
      scoreLeads: 'Приоритезируйте клиентов с помощью искусственного интеллекта',
      driveROI: 'Увеличивайте эффективность рекламных кампаний',
      exploreEnrich:
        'Мы автоматически собираем 60+ внутренних и внешних факторов о каждом запросе за считанные секунды',
      exploreScore: 'и управляйте их статусом в режиме реального времени',
      exploreDrive:
        'Успешно закрывайте больше сделок благодаря росту конверсии отдела продаж и персонализированным продажам',
      exploreAll: 'Изучить все возможности',
      howItWorks: 'Как это работает',
      connect: 'Подключение',
      expand: 'Расширение',
      predict: 'Прогноз',
      manage: 'Управление',
      getNotified: 'Получение уведомлений',
      howConnect: 'Подключите свой сайт в личном кабинете',
      howExpand: 'Каждый запрос с сайта автоматически расширяется до 60+ факторов',
      howPredict:
        'Наш ИИ прогнозирует вероятность сделки, приоритезирует клиента и даёт рекомендации по продажам',
      howManage: 'Управляйте статусом клиентов в режиме реального времени',
      howNotified: 'Вы получаете полную информацию о потенциальном клиенте на свой email',
      readyBoostSales: 'Готовы к росту продаж?',
      getStartedLessMinutes: 'Подключите свой сайт менее чем за 3 минуты!',
      email: 'Ваш email',
      alreadyHaveAccount: 'Уже есть аккаунт?',
      termsOfService: 'Условия использования',
      privacyPolicy: 'Политика конфиденциальности',
      contactUs: 'Свяжитесь с нами',
      productTour: 'Тур по продукту',
      productTitle: 'Глаза вашего отдела продаж',
      productDescription: 'Oculisa способна видеть то, что не видит человек – Большие Данные',
      analiticsTitle: 'Глубокая аналитика для каждого запроса',
      analiticsDescription:
        'Запросы с Вашего сайта отправляются реальными людьми. Oculisa помогает Вам анализировать их онлайн-поведение.',
      analiticsFirstVisit: 'Время первого визита',
      analiticsFirstVisitDescription:
        'Узнайте, когда ваш потенциальный клиент впервые посетил ваш сайт',
      analiticsEndVisit: 'Время окончания визита',
      analiticsEndVisitDescription: 'Определите, в какое время лид покинул ваш сайт',
      analiticsCreationRequest: 'Время создания запроса',
      analiticsCreationRequestDescription:
        'Узнайте, сколько времени занимает у потенциального клиента, чтобы оставить запрос',
      analiticsBrowserInfo: 'Информация о браузере',
      analiticsBrowserInfoDescription:
        'Определите, какой браузер использует ваш потенциальный клиент',
      analiticsDevice: 'Устройство (ОС, язык, разрешение экрана)',
      analiticsDeviceDescription:
        'Определите, с какого устройства потенциальный клиент просматривал ваш сайт',
      analiticsLocation: 'Локация',
      analiticsLocationDescription: 'Определите, из какого места был отправлен запрос',
      analiticsPagesHistory: 'История страниц',
      analiticsPagesHistoryDescription:
        'Предлагайте вашему клиенту только те товары, которые ему интересны',
      analiticsTraffic: 'Источник трафика',
      analiticsTrafficDescription: 'Определить наиболее эффективные рекламные каналы',
      analiticsVisits: 'Визиты (общие, сегодня, страница)',
      analiticsVisitsDescription: 'Проанализируйте вовлеченность вашего потенциального клиента',
      analiticsTime: 'Время на сайте (общее, сегодня, страница)',
      analiticsTimeDescription:
        'Определите степень заинтересованности вашего потенциального клиента',
      analiticsEvents: 'События на сайте',
      analiticsEventsDescription: 'Узнайте элементы, с которыми лид взаимодействовал на сайте',
      analiticsShopping: 'Корзина',
      analiticsShoppingDescription: 'Актуализируйте покупки вашего потенциального клиента',
      internalData: 'Внутренние данные',
      websiteVisitDate: 'Время визита',
      additionalBrowserInfo: 'Дополнительная информация о браузере',
      osDevice: 'ОС/Устройство',
      deviceLanguage: 'Язык устройства/браузера',
      mobileVersion: 'Мобильная версия',
      screenResolution: 'Разрешение экрана при загрузке',
      browserVersion: 'Версия браузера',
      countryVisitor: 'Страна локации',
      subregion: 'Область',
      timeZone: 'Часовой пояс',
      submittedPage: 'Страница отправки заявки',
      submittedPageTitle: 'Заголовок страницы отправки заявки',
      websiteName: 'Подтвержденное имя сайта',
      reffer: 'Источник трафика',
      totalSiteVisits: 'Общее количество визитов',
      todaySiteVisits: 'Визиты сегодня',
      todaySubmittedVisits: 'Визиты страницы отправки заявки сегодня',
      totalTimeSpent: 'Общее время на сайте (мс)',
      todayTimeSpent: 'Время на сайте сегодня (мс)',
      todaySubmittedTimeSpent: 'Время на странице отправки заявки сегодня (мс)',
      htmlIdForm: 'HTML id заполненной формы',
      htmlNameForm: 'HTML имя заполненной формы',
      numberOfInputs: 'Количество заполненных полей',
      userTrustZone: 'Страница отправки заявки относится к зоне доверия пользователя',
      interactionEvents: 'Список элементов взаимодействия',
      reccomendedProducts: 'Рекомендованные продукты',
      outherFactors: 'другиx важных неявных факторов',
      identifyLeadsTitle: 'Идентифицируйте Ваших лидов',
      identifyLeadsDescription: 'Знайте о своих клиентах больше, чем только контактные данные.',
      identifyLeadsDescription2:
        'Наши алгоритмы найдут точную информацию для расширения их клиентского профиля.',
      identifyPhoto: 'Фото',
      identifyPhotoDescription:
        'Получите реальную фотографию вашего потенциального клиента без встречи',
      identifyContacts: 'Контакты',
      identifyContactsDescription: 'Получить актуальные контакты вашего лидера',
      identifyName: 'Полное имя',
      identifyNameDescription: 'Укажите полное имя вашего клиента',
      identifyPosition: 'Должность',
      identifyPositionDescription: 'Узнайте текущую позицию вашего лида',
      identifyAddress: 'Адрес',
      identifyAddressDescription: 'Определите фактический физический адрес вашего лида',
      identifyEducation: 'Образование',
      identifyEducationDescription: 'Узнайте, какое образование имеет ваш потенциальный клиент',
      identifyBirthday: 'Дата рождения',
      identifyBirthdayDescription: 'Поздравьте лида с днем рождения вовремя',
      identifyGroups: 'Подписки в социальных сетях',
      identifyGroupsDescription: 'Узнайте, на какие группы подписан ваш лидер в социальных сетях',
      identifyGender: 'Пол',
      identifyGenderDescription: 'Точно определите пол вашего потенциального клиента',
      identifyExperience: 'Опыт работы или учебы',
      identifyExperienceDescription: 'Узнайте, какой опыт имеет ваш потенциальный клиент',
      identifyStatus: 'Семейное положение',
      identifyStatusDescription: 'Узнайте семейное положение вашего лида',
      identifyChildren: 'Количество детей',
      identifyChildrenDescription: 'Узнайте, сколько детей у вашего потенциального клиента',
      identifySubscribers: 'Количество подписчиков',
      identifySubscribersDescription:
        'Узнайте, насколько популярно ваше лидерство в социальных сетях',
      identifyInterests: 'Интересы',
      identifyInterestsDescription:
        'Узнайте, что интересует вашего потенциального клиента помимо вашего продукта',
      identifyTweets: 'Последние твиты',
      identifyTweetsDescription: 'Узнайте, что ваши ведущие твиты',
      identifyLanguages: 'Языки',
      identifyLanguagesDescription: 'Определите, на каких языках говорит ваш потенциальный клиент',
      empowerSalesAi: 'Усиливайте Ваши продажи с помощью Искусственного Интеллекта',
      empowerSalesAiDescription:
        'Благодаря нашей модели отделы продаж фокусируются на более ценных клиентах и максимизируют прибыль компании',
      empowerSalesScoring: 'Предиктивный Лид Скоринг',
      empowerSalesScoringDescription:
        'Сосредоточьтесь на более ценных лидерах благодаря интеллектуальному прогнозированию AI',
      empowerSalesForecasting: 'Прогнозирование вероятности сделки',
      empowerSalesForecastingDescription:
        'Узнайте вероятность успешной сделки, прежде чем связаться с потенциальным клиентом',
      empowerAdvices: 'Рекомендации по продажам дополнительных продуктов',
      empowerAdvicesDescription: 'Продавайте больше благодаря персональным рекомендациям нашего AI',
      manageLeads: 'Управляйте Вашими лидами в интуитивно понятном интерфейсе',
      manageLeadsDescription:
        'Рейтинговые таблицы обновляются в режиме реального времени – сокращайте время на поиск наилучшей возможности для совершения сделки.',
      priceTitle: 'Простые цены, которые подходят любому бизнесу',
      priceDescription:
        'Без скрытых платежей. Без автоматического продления. Без долгосрочных обязательств.',
      priceChooseTariff: 'Сравните тарифные планы',
      tariffs: 'Тарифы',
      priceStartup: 'Старт-Ап',
      priceGrowth: 'Старт-Ап+',
      priceEnterprise: 'Компания',
      priceCorporate: 'Энтерпрайз',
      priceUpTo: 'до',
      priceFrom: 'с',
      priceLeads: 'заявок',
      pricePerLead: 'заявка',
      priceSave: 'экономия',
      priceStarted: 'Попробовать бесплатно',
      priceIndividual: 'Свяжитесь с нами',
      priceTarrif: 'Каждый тарифный план включает в себя полный функционал',
      pricePaidLeads: 'Оплаченные заявки не сгорают в конце месяца',
      priceLess: 'Чем больше заявок – тем меньше стоимость',
      name: 'Ваше имя',
      contactEmail: 'Контактный email',
      domainName: 'Сайт',
      requestAverageAmount: 'Среднее количество заявок в месяц:',
      requestLess: 'Меньше',
      requestMore: 'Больше',
      requestComments:
        'Комментарии (какие контактные формы необходимо отслеживать, любая другая информация)',
      submit: 'Отправить',
      yourPassword: 'Пароль',
      signUp: 'Регистрация',
      question: 'Ваш вопрос',
      settings: 'Настройки',
      paymentBalance: 'Оплата и баланс',
      logout: 'Выход',
      dashboardTitle: 'Подключённые сайты',
      dashboardConnectButton: 'Подключить новый сайт',
      dashboardManage: 'Управлять лидами',
      dashboardEdit: 'Редактировать',
      profileSettings: 'Настройки профиля',
      profileMainSettings: 'Основные настройки',
      profileChangePasswordTitle: 'Изменить пароль',
      profileChangePasswordDescription: '(optional)',
      profileChangePasswordButton: 'Сохранить новый пароль',
      save: 'Сохранить изменения',
      profileNameDescription: 'Enter your name so we get to know you better',
      profileEmailDescription: 'Enter your email to receive service notifications.',
      profileNotification: 'Язык уведомлений',
      english: 'Английский',
      russian: 'Русский',
      profileOldPass: 'Старый пароль',
      profileOldPassPlaceholder: 'Старый пароль',
      profileNewPass: 'Новый пароль',
      profileNewPassPlaceholder: 'Новый пароль',
      profileRepeatPass: 'Repeat new password',
      profileRepeatPassPlaceholder: 'Confirm new password',
      forgotPassword: 'Я забыл пароль',
      paymentCurrentBalance: 'Текущий баланс',
      leads: 'лидов',
      paymentPricingInform: 'Цены и тарифы',
      paymnetPurchaseLeads: 'Приобрести лидов',
      paymentSelectText: 'Выберите количество необходимых заявок:',
      paymentAmount: 'Сумма к оплате:',
      currency: '₽',
      paymentTarrif: 'Применяемый тариф:',
      paymentComplete: 'Оплатить',
      newsiteTitle: 'Подключить новый сайт',
      newsiteBasic: 'Основные настройки',
      newsiteTechnical: 'Технические настройки',
      optional: 'опциональные',
      newsiteCodeInstall: 'Установить код на сайт',
      siteDomain: 'Домен сайта',
      siteClients: 'Ваши основные клиенты',
      siteCustomers: 'Конечные потребители',
      siteOther: 'Другие компании',
      siteSelectLanguage: 'Выберите все языки, на которых доступна поддержка Ваших клиентов',
      siteSelectCountries: 'Выберите все страны, в которых Вы оперируете',
      techSettings: 'Инструкция к техническим настройкам',
      techTrackForms: 'Отслеживать только определенные формы.',
      techTrackFormsDescription: 'По умолчанию, отслеживаются все формы с сайта',
      techTrackFormsPlaceholder:
        'Введите CSS-классы форм, которые Вы хотите отслеживать. Разделитель – запятая.',
      techIgnoreForms: 'Игнорирование отслеживания определенных форм',
      techIgnoreFormsPlaceholder:
        'Введите CSS-классы форм, которые необходимо игнорировать. Разделитель – запятая. ',
      techEvents: 'События взаимодействий',
      techEventsPlaceholder: 'Пример: [ { tag: "a", contains: "download", events : [\'click\']} ]',
      techCustomForms: 'Пользовательские формы',
      techShoppingCartDescription:
        'Применимо только для интернет-магазинов. Запросите бесплатную интеграцию в случае возникновения любых трудностей.',
      techShoppingCartPlaceholder: 'Пример',
      techCustomFormsDescription:
        'Для форм, которые не используют стандартный метод <form> и <input type=’submit’>.',
      techFormID: 'ID формы:',
      techFormTrigger: 'Триггер формы:',
      techShoppingCartText: 'Настройки для ecommerce',
      techInstallBeforeTitle: 'Перед закрывающим тегом </body> вставьте код:',
      copyCode: 'Скопировать код',
      sendCodeAndInstructionsOnEmail: 'Отправить код и инструкции на email',
      editSite: 'Редактировать настройки сайта',
      leadsDashboard: 'управление лидами',
      leadsStatusPending: 'Ожидает',
      leadsStatusProcessing: 'В работе',
      leadsStatusCompleted: 'Завершить',
      leadsStatusSuccessful: 'Успешная сделка',
      leadsStatusFailed: 'Неудачная сделка',
      leadDealProbability: 'Вероятность сделки:',
      leadName: 'Имя',
      leadPhone: 'Телефон',
      leadEmail: 'Email',
      leadAdress: 'Адрес',
      leadPosition: 'Должность',
      leadViewProfile: 'Открыть профиль',
      leadDelete: 'Удалить',
      leadStatus: 'Статус:',
      comment: 'Комментарий',
      dashboardLeadsStatusCompleted: 'Завершено',
      back: 'Назад',
      lead: 'Лид',
      profile: ', Профиль',
      externalData: 'Внешние данные',
      contactsAdditional: 'Дополнительные контакты',
      twitterSubs: 'Количество подписчиков в Twitter',
      signUpTitile: 'Осталось всего несколько шагов',
      signUpTitleDescription: 'для роста Ваших продаж!',
      confirmPass: 'Повторите пароль',
      signUpDescription: 'Нажимая на «Получить 5 лидов бесплатно», Вы соглашаетесь с нашими',
      signUpDescription2: 'Узнайте, как мы собираем и используем Ваши данные в нашей',
      signUpDescription3:
        'Вы можете получать наши электронные письма вместе с обновлениями продукта и уведомлениями.',
      signUpTerms: 'Условиями предоставления услуг',
      signUpPolicy: 'Политике конфиденциальности',
      password: 'Пароль',
      resetPassword: 'Сброс пароля',
      reset: 'Сброс',
      update: 'Обновить',
      passwordForgot: 'Забыли пароль?',
      passwordShort: 'Пароль слишком короткий',
      fieldRequired: 'Это поле обязательно к заполнению',
      passwordsShouldMatch: 'Пароли должны совпадать',
      cookieText: 'Используя этот сайт, вы соглашаетесь с нашей ',
      iAgree: 'Я согласен',
      cookiePolicy: 'политикой конфиденциальности',
      emailVerified: 'электронная почта подтверждена',
      emailUnverified: 'электронная почта не подтверждена',
    },
  },
};
