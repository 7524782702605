import React, { Component } from 'react';
import ProductIntro from '../../components/Product/ProductIntro';
import DeepAnalytics from '../../components/Product/DeepAnalytics';
import IdentifyYourLeads from '../../components/Product/IdentifyYourLeads';
import EmpowerSales from '../../components/Product/EmpowerSales';
import ManageLeads from '../../components/Product/ManageLeads';
import LeadForm from '../../components/Home/LeadForm';

class Product extends Component {

  render(){
    return(
      <div className="container">
        <ProductIntro/>
        <DeepAnalytics/>
        <IdentifyYourLeads/>
        <EmpowerSales/>
        <ManageLeads/>
        <LeadForm/>
      </div>
    );
  }
}

export default Product;