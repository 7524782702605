import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Dropdown, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import classNames from 'classnames';
import useWindowSize from '../../customHooks/useWindowSize';
import RequestIntegrationModal from './RequestIntegrationModal';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

import { useTranslation, initReactI18next } from 'react-i18next';

const oculisaLogo = require('../../assets/images/logo.svg');

function Header({ location }) {
  const { t } = useTranslation();

  const toggleRef = useRef(null);
  const [integrationModalOpen, toggleIntegrationModal] = useState(false);
  const [navbarOpen, toggleNavbar] = useState(false);
  const size = useWindowSize();

  const showGradient = location.pathname === '/' || location.pathname === '/product';
  const navBarLinkColor = classNames({
    'violet-color': !showGradient,
  });

  useEffect(() => {
    if (size.width > 991 && navbarOpen) {
      handleToggleLine(false);
    }
  }, [size, navbarOpen]);

  const handleToggleLine = (status) => {
    toggleNavbar(status);

    status
      ? document.body.classList.add('overflowHidden')
      : document.body.classList.remove('overflowHidden');
  };

  const handleRequestIntegration = () => {
    toggleNavbar(false);
    toggleIntegrationModal(true);
    handleToggleLine(false);
  };

  const toggleHandler = () => {
    handleToggleLine(!navbarOpen);
  };

  const toggleIntegrationModalHandler = () => {
    toggleIntegrationModal(!integrationModalOpen);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={classNames('container header-container', {
        'menu-open': navbarOpen,
      })}
    >
      <Navbar.Brand>
        <Link className="navbar-brand-logo" to="/">
          <img src={oculisaLogo} alt="oculisa logo" />
        </Link>
        <LanguageSwitcher />
      </Navbar.Brand>
      <LinkContainer to="/get-started" className="get-started tablet">
        <Nav.Link>
          {document.documentElement.lang === 'en' ? 'Get Started' : 'Установить на сайт'}
        </Nav.Link>
      </LinkContainer>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        className={`${navbarOpen ? 'opened-toggler' : ''}`}
        ref={toggleRef}
        onClick={toggleHandler}
      >
        <span className="toggle-line"></span>
        <span className="toggle-line"></span>
        <span className="toggle-line"></span>
      </Navbar.Toggle>
      <Navbar.Collapse
        id="basic-navbar-nav"
        className={`${navBarLinkColor} ${navbarOpen ? 'open-menu' : ''}`}
      >
        <Nav>
          <LinkContainer to="/product" onClick={() => handleToggleLine(false)}>
            <Nav.Link>{t('product')}</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/pricing" onClick={() => handleToggleLine(false)}>
            <Nav.Link>{t('pricing')}</Nav.Link>
          </LinkContainer>
          <LinkContainer
            to="/get-started"
            onClick={() => handleToggleLine(false)}
            className="get-started"
          >
            <Nav.Link>{t('getStarted')}</Nav.Link>
          </LinkContainer>

          <LanguageSwitcher />
        </Nav>
        <Nav className="right-links">
          <span onClick={handleRequestIntegration}>
            <Nav.Link>{t('requestAnIntegration')}</Nav.Link>
          </span>
          <LinkContainer to="/login" onClick={() => handleToggleLine(false)}>
            <Nav.Link className="last">{t('logIn')}</Nav.Link>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
      <RequestIntegrationModal
        toggleIntegrationModal={toggleIntegrationModalHandler}
        integrationModalOpen={integrationModalOpen}
      />
    </Navbar>
  );
}

export default Header;
