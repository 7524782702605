import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import StatusSteps from '../../components/Dashboard/StatusSteps';
import ExternalData from '../../components/Dashboard/ExternalData';
import InternalData from '../../components/Dashboard/InternalData';
import { withTranslation } from 'react-i18next';
import * as leadsApi from 'api/leads';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

const chevronLeft = require('../../assets/images/chevron-left.svg');

class LeadProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lead: {},
    };
  }

  async componentDidMount() {
    this.getLead();
  }

  getLead = async () => {
    try {
      const response = await leadsApi.getLead(this.props.match.params.leadId);

      this.setState({ lead: response.data.data });
    } catch (error) {
      console.dir(error);
      toast.error(error.response.data.message);
    }
  };

  onBack = () => {
    this.props.history.goBack();
  };

  deleteLead = async () => {
    try {
      const response = await leadsApi.deleteLead(this.state.lead.lead_id);
      toast.success('Success!');
      this.onBack();
    } catch (error) {
      console.dir(error);
      toast.error(error.response.data.message);
    }
  };

  updateLead = async (data) => {
    try {
      const response = await leadsApi.updateLead(this.state.lead.lead_id, data);

      this.getLead();
    } catch (error) {
      console.dir(error);
      toast.error(error.response.data.message);
    }
  };

  render() {
    const { lead } = this.state;

    if (isEmpty(lead)) {
      return 'No lead';
    }

    const {
      email,
      email_verified,
      phone,
      photo,
      name,
      updated_at,
      comment,
      predicted_probability,
      lead_status,
    } = lead;

    return (
      <div className="container lead-profile">
        <div className="inside">
          <button onClick={this.onBack} className="btn purple-button back">
            <img src={chevronLeft} alt="Chevron left" />
            <span>{this.props.t('back')}</span>
          </button>
          <div className="profile-details">
            <p className="lead-number-title">
              {this.props.t('lead')}{': '}
              {/* {this.props.t('profile')} */}
              {name}
            </p>
            <div className="flex-container top">
              <div className="avatar-l">
                <img
                  className="leads-avatar"
                  src={
                   ( photo && photo !== 'None') ?   photo :
                    'https://www.deadline.com.ua/design/img/default-avatar.png'
                  }
                  alt="Leads avatar"
                />
              </div>
              <div className="details-1">
                <div className="two-row">
                  <p className="label">{this.props.t('leadName')}</p>
                  <p className="text">{name}</p>
                </div>
                <div className="two-row">
                  <p className="label">{this.props.t('leadPhone')}</p>
                  <p className="text">{phone}</p>
                </div>
                <div className="two-row">
                  <p className="label">{this.props.t('leadEmail')}</p>
                  <p className="text">
                    <span
                      title={
                        email_verified
                          ? this.props.t('emailVerified')
                          : this.props.t('emailUnverified')
                      }
                      className={email_verified ? 'verified' : 'unverified'}
                    ></span>
                    {email}
                  </p>
                </div>
              </div>
              <div className="details-2">
                <p className="date">{updated_at}</p>
                <p className="text">
                  {this.props.t('leadDealProbability')}{' '}
                  <span style={{ fontWeight: 'bold', color: '#623695' }}>
                    {predicted_probability}%
                  </span>
                </p>
              </div>
            </div>
            <div className="flex-container comments">
              <span className="status-heading">{this.props.t('leadStatus')}</span>
              <StatusSteps
                status={lead_status}
                updateLead={(data) => {
                  this.updateLead(data);
                }}
              />
            </div>
            <Form.Group className="comment-form-container">
              <Form.Control
                as="textarea"
                placeholder={this.props.t('comment')}
                value={comment}
                onBlur={() => {
                  this.updateLead({ comment: this.state.lead.comment });
                }}
                onChange={(e) =>
                  this.setState({
                    lead: {
                      ...this.state.lead,
                      comment: e.target.value,
                    },
                  })
                }
                className="oculisa-textfield textarea"
              />
            </Form.Group>
            <div className="external-data">
              <ExternalData lead={this.state.lead} />
              <InternalData lead={this.state.lead} />
            </div>
          </div>
          <button onClick={this.deleteLead} className="btn purple-button del">
            <i className="fa fa-trash" aria-hidden="true" />
            <span>{this.props.t('leadDelete')}</span>
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(LeadProfile));
