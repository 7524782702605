import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import RequestIntegrationModal from './RequestIntegrationModal';
import { connect } from 'react-redux';
import { logOut } from 'redux/user/actions';
import { useTranslation, initReactI18next } from 'react-i18next';
import useWindowSize from '../../customHooks/useWindowSize';
import { toast } from 'react-toastify';
import { getBalance } from '../../api/balance';

const oculisaLogo = require('../../assets/images/oculisa-white-logo.svg');
const integrationLogo = require('../../assets/images/integration-logo.svg');
const gearsLogo = require('../../assets/images/gears-icon.svg');
const paymentLogo = require('../../assets/images/payment-logo.svg');

function LoggedInHeader({ location, logOut }) {
  const [integrationModalOpen, toggleIntegrationModal] = useState(false);
  const [navbarOpen, toggleNavbar] = useState(false);
  const size = useWindowSize();
  const [balance, setBalance] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (size.width > 991 && navbarOpen) {
      handleToggleLine(false);
    }
  }, [size, navbarOpen]);

  const getBalanceApi = async () => {

    try {
      const balance = await getBalance();

      if (balance !== balance.data.leadBalance) {
        setBalance(balance.data.leadBalance);
      }
    } catch (error) {
      console.dir(error);
      // toast.error(JSON.stringify(error));
    }
  }

  useEffect(() => {
    setInterval(() => {
      getBalanceApi();
    }, 1000 * 10);
  }, [getBalanceApi]);




  const handleToggleLine = (status) => {
    toggleNavbar(status);

    status
      ? document.body.classList.add('overflowHidden')
      : document.body.classList.remove('overflowHidden');
  };

  const toggleHandler = () => {
    handleToggleLine(!navbarOpen);
  };

  return (
    <Navbar collapseOnSelect expand="lg" className="logged-in-navbar">
      <div className="container">
        <Navbar.Brand>
          <Link className="navbar-brand" to="/personal-cabinet">
            <img src={oculisaLogo} alt="oculisa logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className={`${navbarOpen ? 'opened-toggler' : ''}`}
          onClick={toggleHandler}
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className={`${navbarOpen ? 'open-menu' : ''} justify-content-end`}
        >
          <Nav className="mr-auto" />
          <Nav>
            <span onClick={() => toggleIntegrationModal(true)}>
              <Nav.Link>
                <img src={integrationLogo} alt="Integration logo" className="navlink-logo" />
                {t('requestAnIntegration')}
              </Nav.Link>
            </span>
            {/* <LinkContainer to="/settings">
              <Nav.Link>
                <img src={gearsLogo} alt="Gears logo" className="navlink-logo" />
                {t('settings')}
              </Nav.Link>
            </LinkContainer> */}
            <LinkContainer to="/payment-and-balance">
              <Nav.Link>
                <img src={paymentLogo} alt="Payment logo" className="navlink-logo" />
                {t('paymentBalance')}
               {balance &&  <span className="notification-pill">{balance}</span>}
              </Nav.Link>
            </LinkContainer>
            <Link onClick={logOut} to="/" className="last">
              {t('logout')}
            </Link>
          </Nav>
        </Navbar.Collapse>
        <RequestIntegrationModal
          toggleIntegrationModal={() => toggleIntegrationModal(!integrationModalOpen)}
          integrationModalOpen={integrationModalOpen}
        />
      </div>
    </Navbar>
  );
}

export default connect(null, { logOut })(LoggedInHeader);
