import { ModalContext } from 'context/modal';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { Link, withRouter } from 'react-router-dom';
import { getBalance } from '../../api/balance';
import { toast } from 'react-toastify';
import CurrencySelect from 'containers/Pricing/CurrencySelect/CurrencySelect';
import { currencySymbols, prices } from 'containers/Pricing/prices';
import './Payment.scss';
import Amplify, { Auth } from 'aws-amplify';
import { createOrder } from 'api';

const masterCardLogo = require('../../assets/images/master-card-logo.svg');
const verifiedByVisaLogo = require('../../assets/images/verified-by-visa.svg');

class Payment extends Component {
  state = {
    leadsCount: 100,
    balance: 0,
    currency: 'usd',
  };

  getBalance = async () => {
    try {
      const balance = await getBalance();
      this.setState({ balance: balance.data.leadBalance });
    } catch (error) {
      console.dir(error);
      toast.error(JSON.stringify(error));
    }
  };

  changeLeads = (inc) => {
    let { leadsCount } = this.state;

    let value = (leadsCount += inc ? 10 : -10);

    if (value < 0) {
      value = 0;
    }

    this.setState({ leadsCount: value });
  };

  componentDidMount() {
    this.getBalance();
  }

  async createO(d) {
    try {
      const r = await createOrder(d);
      return r.data.data.id;
    } catch (e) {
      console.log(e);
      alert('Create order error');

      return;
    }
  }

  pay = async () => {
    // this.context('pay');

    const { currentTariff, pricePerLead, currencySymbol } = this.calculate();

    const orderId = await this.createO({
      currency: this.state.currency.toUpperCase(),
      amount: this.state.leadsCount * pricePerLead,
    });

    if (!orderId) {
      return;
    }

    this.props.history.push(
      `pay?leadsCount=${
        this.state.leadsCount
      }&currency=${this.state.currency.toUpperCase()}&price=${(
        this.state.leadsCount * pricePerLead
      ).toFixed(2)}&orderId=${orderId}`
    );
  };

  // rewrite to Hook and delete this
  calculate = () => {
    const currentTariff =
      prices.find((tariff) => this.state.leadsCount <= tariff.leadsTill) || prices[3]; // Corporate tariff
    const pricePerLead = (currentTariff.name !== 'corporate' ? currentTariff : prices[2]).prices[
      this.state.currency
    ];
    const currencySymbol = currencySymbols[this.state.currency];

    return { currentTariff, pricePerLead, currencySymbol };
  };

  onSelectTarif = (curr) => {
    this.setState({ currency: curr });
  };
  render() {
    const { leadsCount } = this.state;
    const { currentTariff, pricePerLead, currencySymbol } = this.calculate();
    return (
      <div className="container payments Payment">
        <Row>
          <Col lg="4" md="6" sm="12">
            <div className="current-balance">
              <p className="title">{this.props.t('paymentCurrentBalance')}</p>
              <p className="leads-number">
                {this.state.balance} {this.props.t('leads')}
              </p>
              <div className="line-button-container">
                <Link to="/pricing" className="line-button">
                  {this.props.t('paymentPricingInform')}
                </Link>
              </div>
              <span className="icon-container clickable">
                <span className="icon-reload-icon" />
              </span>
            </div>
            <div className="cards-logo-container">
              <img className="cards-logo" src={masterCardLogo} alt="Master card logo" />
              <img className="cards-logo ml-10" src={verifiedByVisaLogo} alt="Visa card logo" />
            </div>
          </Col>
          <Col lg="8" md="6" sm="12">
            <div className="current-balance">
              <p className="title">{this.props.t('paymnetPurchaseLeads')}</p>
              <p className="request-number-label">{this.props.t('paymentSelectText')}</p>
              <div className="payment-amount">
                <div className="number">
                  {/* // TODO: change to buttons and input */}
                  <span className="controls clickable" onClick={() => this.changeLeads(false)}>
                    -
                  </span>
                  <span className="request-number">{leadsCount}</span>
                  <span className="controls clickable" onClick={() => this.changeLeads(true)}>
                    +
                  </span>
                </div>
                <div className="amount">
                  <p className="label">{this.props.t('paymentAmount')}</p>
                  <p className="total">
                    {/* {this.props.t('currency')} */}
                    {(leadsCount * pricePerLead).toFixed(2)}
                    {currencySymbol}{' '}
                    <span>
                      ({pricePerLead}
                      {currencySymbol} {this.props.t('pricePerLead')})
                    </span>
                  </p>
                </div>
              </div>
              <CurrencySelect onChange={this.onSelectTarif} />
              <div className="tariff">
                <span className="label">{this.props.t('paymentTarrif')}</span>
                <span className="tariff-type">{this.props.t(currentTariff.i18nText)}</span>
                {currentTariff.discount && (
                  <span className="percent">-{currentTariff.discount}%</span>
                )}{' '}
                {/* (<Link to="/pricing">{this.props.t('tariffs')}</Link>) */}
              </div>
              <div className="submit-section">
                <button className="purple-button btn" onClick={this.pay}>
                  {this.props.t('paymentComplete')}
                </button>
                {currentTariff.name === 'corporate' && (
                  <>
                    {' '}
                    {this.props.t('or')}{' '}
                    <button className="btn-link" onClick={() => this.context('contact-us')}>
                      {this.props.t('contactUs')}
                    </button>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

Payment.contextType = ModalContext;

export default withRouter(withTranslation()(Payment));
