import React from 'react';
import { Navbar, Nav, NavDropdown, Dropdown, NavItem } from 'react-bootstrap';
import i18n from 'i18next';

import './LanguageSwitcher.scss';

import PropTypes from 'prop-types';

const languageIcon = require('../../assets/images/world-icon.svg');
const chevronDown = require('../../assets/images/header-language-selector-chevron.svg');

const LanguageSwitcher = ({ withIcon }) => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    document.documentElement.lang = lng;
  };

  // FIXME: quick fix dropdown, don't use document.documentElement.lang and don't copy it
  return (
    <Dropdown as={NavItem} className="language-selector-dropdown">
      {withIcon ? <img src={languageIcon} alt="" className="language-icon" /> : null}
      <Dropdown.Toggle as={Nav.Link}>
        <span className="title">
          {document.documentElement.lang === 'en' ? 'English' : 'Russian'}
        </span>
        <img src={chevronDown} className="chevron-down" alt="Chevron down src" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <NavDropdown.Item
          href="#"
          onClick={() => changeLanguage(document.documentElement.lang === 'en' ? 'ru' : 'en')}
        >
          {document.documentElement.lang !== 'en' ? 'English' : 'Russian'}
        </NavDropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

LanguageSwitcher.propTypes = {};

export default LanguageSwitcher;
