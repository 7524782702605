import { isEmpty } from 'lodash';

// TODO: cobmine new and edit website
export function validate(form, setErrors) {
  const errors = {};

  if (!form.siteDomain) {
    errors.siteDomain = 'Required field';
  }

  if (isEmpty(form.supportLanguages)) {
    errors.supportLanguages = 'Required field';
  }

  if (isEmpty(form.operatingCountries)) {
    errors.operatingCountries = 'Required field';
  }

  if (isEmpty(errors)) {
    return true;
  } else {
    setErrors(errors);
    return false;
  }
}
