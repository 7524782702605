import React from 'react';

import { ReactComponent as Person1 } from './images/1.svg';
import { ReactComponent as Person2 } from './images/2.svg';
import { ReactComponent as Person3 } from './images/3.svg';
import { ReactComponent as Person4 } from './images/4.svg';
import { ReactComponent as Person5 } from './images/5.svg';
import { ReactComponent as Person6 } from './images/6.svg';
import { useTranslation, initReactI18next } from 'react-i18next';

import classNames from 'classnames';

import './People.scss';

const propTypes = {};

const data = [
  {
    Image: Person1,
    i18nText: 'landingUser1',
  },
  {
    Image: Person2,
    i18nText: 'landingUser2',
  },
  {
    Image: Person3,
    i18nText: 'landingUser3',
  },
  {
    Image: Person4,
    i18nText: 'landingUser4',
  },
  {
    Image: Person5,
    i18nText: 'landingUser5',
  },
  {
    Image: Person6,
    i18nText: 'landingUser6',
  },
];

const People = () => {
  const { t } = useTranslation();

  return (
    <div className="people">
      {data.map((person, i) => {
        const { Image, i18nText } = person;
        return (
          <div key={i} className={classNames('person', `person-${i + 1}`)}>
            <Image />
            <span>{t(i18nText)}</span>
          </div>
        );
      })}
    </div>
  );
};

People.propTypes = propTypes;

export default People;
