import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import configRoutes from 'routes';
import { Modal } from 'react-bootstrap';
import { auth, logOut } from 'redux/user/actions';
import { connect } from 'react-redux';

import Amplify, { Auth } from 'aws-amplify';

import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
// import newSteps from './steps'
import 'shepherd.js/dist/css/shepherd.css';

import { ModalContext } from 'context/modal';
import Terms from 'components/Terms/Terms';
import Privacy from 'components/Privacy/Privacy';
import Cookie from 'components/Cookie/Cookie';
import ContactUs from 'components/Common/ContactUsModal';
import { ToastContainer } from 'react-toastify';

import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import { lang } from './lang';
import { useEffect } from 'react';
import './App.scss';
// import PayModal from 'containers/Payment/PayModal/PayModal';

const closeIcon = require('../../assets/images/close-icon.svg');

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
};

const defaultLanguage =
  localStorage.getItem('language') || navigator.language.split('-')[0] || 'en';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: lang,
    lng: defaultLanguage,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

document.documentElement.lang = defaultLanguage;

const App = ({ auth, history, location }) => {
  const [isShowModal, setModal] = useState({
    opened: false,
    name: '',
  });

  useEffect(() => {
    (async () => {
      try {
        const currentSession = await Auth.currentSession();

        const token = currentSession.accessToken.jwtToken;

        if (token) {
          auth(token);
          if (location.pathname === '/') {
            history.push('/personal-cabinet');
          }
        } else {
          history.push('/');
          logOut();
        }
      } catch (error) {
        console.dir(error);
      }
    })();

    history.listen(() => {
      window.scrollTo(0, 0);
    });
  }, [auth, history, location.pathname]);

  const [isShowCookie, setCookie] = useState(localStorage.getItem('cookieConfirmed') || false);
  function openModal(name, params) {
    setModal({
      opened: true,
      name,
    });

    // document.body.style.overflow = 'hidden';
  }

  function closeModal() {
    setModal({
      opened: false,
    });

    // document.body.style.overflow = 'Pauto';
  }

  function renderModal(name, params) {
    let Component = null;

    switch (name) {
      case 'terms':
        Component = Terms;
        break;
      case 'privacy':
        Component = Privacy;
        break;
      case 'contact-us':
        Component = ContactUs;
        break;

      // case 'pay':
      //   Component = PayModal;
      //   break;

      default:
        return null;
    }

    return <Component close={closeModal} />;
  }

  const changeClick = () => {
    setCookie(true);
    localStorage.setItem('cookieConfirmed', true);
  };

  return (
    <>
      <ShepherdTour steps={[]} tourOptions={tourOptions}>
        <ModalContext.Provider value={openModal}>{configRoutes()}</ModalContext.Provider>
        {!isShowCookie && <Cookie changeClick={changeClick} />}

        <Modal
          className="request-integration"
          show={isShowModal.opened}
          onHide={closeModal}
          backdropClassName="custom-modal-backdrop"
          dialogClassName="oculisa-custom-modal"
        >
          <Modal.Header>
            <Modal.Title className="modal-title"></Modal.Title>
            <div className="close-button-container" onClick={closeModal}>
              <img src={closeIcon} alt="Close modal icon" />
            </div>
          </Modal.Header>
          <Modal.Body>{isShowModal.opened && renderModal(isShowModal.name)}</Modal.Body>
        </Modal>

        <ToastContainer />
      </ShepherdTour>
    </>
  );
};

export default withRouter(connect(null, { auth })(App));
