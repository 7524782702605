import React, { useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import OculisaTextField from '../../../components/Common/OculisaTextField';
import { Button } from 'components/Common';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import './NewPassword.scss';

const NewPassword = ({ history, location }) => {
  const { t } = useTranslation();
  const { search } = location;
  const token = search && search.split('=')[1];
  const [form, setForm] = useState({
    password: '',
    password2: '',
    error: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  const validate = () => {
    const e = {};
    const { password, password2 } = form;

    if (password.length < 8) {
      e.password = t('passwordShort');
    }

    if (password2.length < 8) {
      e.password2 = t('passwordShort');
    }

    if (!password) {
      e.password = t('fieldRequired');
    }

    if (!password2) {
      e.password2 = t('fieldRequired');
    }

    if (password !== password2) {
      e.password = t('passwordsShouldMatch');
      e.password2 = t('passwordsShouldMatch');
    }

    if (!isEmpty(e)) {
      setErrors(e);
      return false;
    }

    return true;
  };

  const onSubmit = async event => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    resetPassword();
  };

  const resetPassword = async () => {
    const { password } = form;
    try {
      //   await axios.patch(
      //     '/password/reset',
      //     {
      //       password,
      //     },
      //     {
      //       params: {
      //         token,
      //       },
      //     }
      //   );
      history.push(`/login`);
    } catch (error) {
      console.dir(error);
      //   setForm({ serverError: error.response.data.errors.base });
    }
  };

  return (
    <Form className="new-password" onSubmit={onSubmit}>
      <Form.Group className="new-password-content" as={Col} sm={12} md={12} lg={12}>
        <h3 className="heading-text">{t('resetPassword')}</h3>
        <OculisaTextField
          label={t('password')}
          value={form.password}
          name="password"
          error={errors.password}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group className="new-password-content" as={Col} sm={12} md={12} lg={12}>
        <OculisaTextField
          label={t('confirmPass')}
          value={form.password2}
          name="password2"
          error={errors.password2}
          onChange={handleChange}
        />
      </Form.Group>
      <Button size="lg" type="submit" className="modal-submit-btn take-full-width">
        {t('update')}
      </Button>
    </Form>
  );
};

export default withRouter(NewPassword);
