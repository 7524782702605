import React, { Component } from 'react';
import BannerSection from '../../components/Home/BannerSection';
import ExploreSection from '../../components/Home/ExploreSection';
import HowItWorksSection from '../../components/Home/HowItWorksSection';
import LeadForm from '../../components/Home/LeadForm';

class Home extends Component {

  render(){
    return(
      <div className="container">
        <BannerSection/>
        <ExploreSection/>
        <HowItWorksSection/>
        <LeadForm/>
      </div>
    );
  }
}

export default Home;