import React from 'react';
import { Button } from '../Common';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './Cookie.scss';

const Cookie = ({ changeClick }) => {
  const { t } = useTranslation();
  return (
    <div className="cookie">
      <h6>
        {t('cookieText')}
        <Link to="/privacy-policy">{t('cookiePolicy')}</Link>
      </h6>
      <Button onClick={changeClick}>{t('iAgree')}</Button>
    </div>
  );
};

export default Cookie;
