import React from 'react';
import { Row, Col } from 'react-bootstrap';

const LanguageSelectDropdownMenu = ({ list, onSelect, contries }) => {
  function onChange(event) {
    const { checked, name } = event.target;
    onSelect(checked, name);
  }

  return (
    <Row>
      {list.map((language) => {
        const id = language.id.toLowerCase();
        return (
          <Col key={language.id} className="language-item" sm={6} md={6} lg={4}>
            <label className="language-name">
              <input
                type="checkbox"
                onChange={onChange}
                name={id}
                checked={contries.includes(id)}
              />
              <span className="checkmark_lang"></span>
              {language.title}
            </label>
          </Col>
        );
      })}
    </Row>
  );
};

export default LanguageSelectDropdownMenu;
