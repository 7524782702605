import React, { useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import OculisaTextField from '../../../components/Common/OculisaTextField';
import { Button } from 'components/Common';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import './ResetPasswordForm.scss';

const ResetPasswordForm = ({ changeForm }) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    email: '',
    error: '',
    serverError: '',
  });

  const handleChange = value => {
    setState({ email: value, error: '', serverError: '' });
  };

  const onSubmit = event => {
    event.preventDefault();
    if (!state.email) {
      setState({ error: 'Enter email' });
      return;
    }

    forgotPassword();
  };

  const forgotPassword = async () => {
    const { email } = state;
    try {
      // await axios.post('/password/forgot', {
      //   email,
      // });
      changeForm('success', email);
    } catch (error) {
      // setState({ serverError: error.response.data.errors.base });
      // console.log(error);
    }
  };

  return (
    <div className="container reset-password-form">
      <Form className="form-content" onSubmit={onSubmit}>
        <Form.Group as={Col} sm={12} md={12} lg={12}>
          <h3 className="heading-text">{t('resetPassword')}</h3>
          <OculisaTextField
            label={t('email')}
            value={state.email}
            type="email"
            error={state.errors}
            name="email"
            onChange={handleChange}
          />
        </Form.Group>
        <Button size="sm" type="submit" className="modal-submit-btn take-full-width">
          {t('reset')}
        </Button>
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
