import axios from 'axios';

export function getLeads(filter, limit) {
  return axios.get('/leads', {
    params: {
      filter,
      limit,
    },
  });
}

export function getLead(id) {
  return axios.get(`/leads/${id}`);
}

export function updateLead(id, data) {
  return axios.put(`/leads/${id}`, data);
}

export function deleteLead(id) {
  return axios.delete(`/leads/${id}`);
}
