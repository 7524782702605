import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'components/Common';
import { useTranslation, initReactI18next } from 'react-i18next';

const identifyLeads = require('../../assets/images/identify-leads.svg');

function IdentifyYourLeads() {
  const { t } = useTranslation();
  return (
    <section className="homepage-section deep-analytics identify-leads">
      <Row>
        <Col sm={12} md={7} lg={8} className="product__title--block">
          <div className="deep-analytics-text">
            <h2 className="header">{t('identifyLeadsTitle')}</h2>
            <h3 className="details">{t('identifyLeadsDescription')}</h3>
            <h3 className="details">{t('identifyLeadsDescription2')}</h3>
          </div>
        </Col>
        <Col className="banner-section-left" sm={12} md={5} lg={4}>
          <img src={identifyLeads} alt="Identify leads source" />
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('identifyPhoto')}</p>
          <p className="analytics-point-desc">{t('identifyPhotoDescription')} </p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('identifyContacts')}</p>
          <p className="analytics-point-desc">{t('identifyContactsDescription')}</p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('identifyName')}</p>
          <p className="analytics-point-desc">{t('identifyNameDescription')}</p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('identifyPosition')}</p>
          <p className="analytics-point-desc">{t('identifyPositionDescription')}</p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('identifyAddress')}</p>
          <p className="analytics-point-desc">{t('identifyAddressDescription')}</p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('identifyEducation')}</p>
          <p className="analytics-point-desc">{t('identifyEducationDescription')}</p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('identifyBirthday')}</p>
          <p className="analytics-point-desc">{t('identifyBirthdayDescription')}</p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('identifyGroups')}</p>
          <p className="analytics-point-desc">{t('identifyGroupsDescription')}</p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('identifyGender')}</p>
          <p className="analytics-point-desc">{t('identifyGenderDescription')}</p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('identifyExperience')}</p>
          <p className="analytics-point-desc">{t('identifyExperienceDescription')}</p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('identifyStatus')}</p>
          <p className="analytics-point-desc">{t('identifyStatusDescription')}</p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('identifyChildren')}</p>
          <p className="analytics-point-desc">{t('identifyChildrenDescription')}</p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('identifySubscribers')}</p>
          <p className="analytics-point-desc">{t('identifySubscribersDescription')}</p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('identifyInterests')}</p>
          <p className="analytics-point-desc">{t('identifyInterestsDescription')}</p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('identifyTweets')}</p>
          <p className="analytics-point-desc">{t('identifyTweetsDescription')}</p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('identifyLanguages')}</p>
          <p className="analytics-point-desc">{t('identifyLanguagesDescription')}</p>
        </Col>
      </Row>
      <Row className="analytics__bottom--row">
        <Col className="analytics-points" sm={12} md={4} lg={6}>
          <p className="more-factors-text">+5 {t('outherFactors')}</p>
        </Col>
        <Col className="analytics-points text-right" sm={12} md={4} lg={6}>
          <Button to="/get-started" className="get-started-button">
            {t('getStarted')}
          </Button>
        </Col>
      </Row>
    </section>
  );
}

export default IdentifyYourLeads;
