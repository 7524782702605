import React, { useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import LeadForm from '../../components/Home/LeadForm';
import { Link } from 'react-router-dom';
import { useTranslation, initReactI18next } from 'react-i18next';

import { ModalContext } from 'context/modal';
import CurrencySelect from './CurrencySelect/CurrencySelect';
import { currencySymbols } from './prices';

const checkedIcon = require('../../assets/images/checked.svg');

const conv = currencySymbols; // delete

const prices = {
  usd: ['1.99', '1.49', '0,99'],
  uah: ['49,99', '37,49', '24,99'],
  rub: ['128,99', ' 96,99', '63,99'],
};

const Pring = () => {
  const { t } = useTranslation();
  const [selectedTarif, onSelectTarif] = useState('usd');

  const openModal = useContext(ModalContext);

  return (
    <div className="container pricing-container">
      <h1 className="heading-text">{t('priceTitle')}</h1>
      <p className="heading-desc">{t('priceDescription')}</p>
      <div className="choose-tarif flex-container">
        <div style={{ marginRight: 30 }}>
          <p className="tarif-label">{t('priceChooseTariff')}</p>
        </div>
        <CurrencySelect onChange={onSelectTarif} />
      </div>

      <Row>
        <Col xs={6} lg={3} md={3} className="pricing__item">
          <div className="tariff-item">
            <div className="tariff-name green">{t('priceStartup')}</div>
            <div className="tariff-details">
              <div className="tariff-cap">
                <p>{t('priceUpTo')}</p>
                <strong>200 {t('priceLeads')}</strong>
              </div>
              <div className="tariff-rate">
                <p>
                  <span>
                    {conv[selectedTarif]}
                    {prices[selectedTarif][0]}
                  </span>{' '}
                  {t('pricePerLead')}
                </p>
              </div>
              <Link to="/get-started" className="btn">
                {t('priceStarted')}
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={6} lg={3} md={3} className="pricing__item">
          <div className="tariff-item">
            <div className="tariff-name red">{t('priceGrowth')}</div>
            <div className="tariff-details">
              <div className="tariff-cap">
                <p>{t('priceUpTo')}</p>
                <strong>800 {t('priceLeads')}</strong>
              </div>
              <div className="tariff-rate flex-container">
                <p>
                  <span>
                    {conv[selectedTarif]}
                    {prices[selectedTarif][1]}
                  </span>{' '}
                  {t('pricePerLead')}
                </p>
                <p className="save-percent">{t('priceSave')} 25%</p>
              </div>
              <Link to="/get-started" className="btn">
                {t('priceStarted')}
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={6} lg={3} md={3} className="pricing__item">
          <div className="tariff-item">
            <div className="tariff-name blue">{t('priceEnterprise')}</div>
            <div className="tariff-details">
              <div className="tariff-cap">
                <p>{t('priceUpTo')}</p>
                <strong>1500 {t('priceLeads')}</strong>
              </div>
              <div className="tariff-rate flex-container">
                <p>
                  <span>
                    {conv[selectedTarif]}
                    {prices[selectedTarif][2]}
                  </span>{' '}
                  {t('pricePerLead')}
                </p>
                <p className="save-percent">{t('priceSave')} 50%</p>
              </div>
              <Link to="/get-started" className="btn">
                {t('priceStarted')}
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={6} lg={3} md={3} className="pricing__item">
          <div className="tariff-item">
            <div className="tariff-name purple">{t('priceCorporate')}</div>
            <div className="tariff-details">
              <div className="tariff-cap">
                <p>{t('priceFrom')}</p>
                <strong>1500 {t('priceLeads')}</strong>
              </div>
              <div className="tariff-rate flex-container">
                <p>
                  <span>{t('priceIndividual')}</span>
                </p>
              </div>
              <button onClick={() => openModal('contact-us')} className="btn">
                {t('contactUs')}
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <div className="tariff-tips">
        <li>
          <img src={checkedIcon} alt="checked icon" />
          <span>{t('priceTarrif')}</span>
        </li>
        <li>
          <img src={checkedIcon} alt="checked icon" />
          <span>{t('pricePaidLeads')}</span>
        </li>
        <li>
          <img src={checkedIcon} alt="checked icon" />
          <span>{t('priceLess')}</span>
        </li>
      </div>
      <LeadForm />
    </div>
  );
};

export default Pring;
