import React, { useContext, useEffect, useState } from 'react';
import { ModalContext } from 'context/modal';
import { withRouter } from 'react-router';
import { shallowEqual, useSelector } from 'react-redux';

function PayModal({ location }) {
  const { search } = location;

  const email = useSelector((state) => state.user.email);
  // const [orderId, setOrderId] = useState(null);

  const arr = search.replace('?', '').split('&');
  console.log(arr);

  const leads = arr[0].split('=')[1];
  const currency = arr[1].split('=')[1];
  const amount = +arr[2].split('=')[1];
  const orderId = arr[3].split('=')[1];

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://pay.fondy.eu/latest/checkout-vue/checkout.js';
    script.onload = function () {
      const { host, protocol } = window.location;

      var Options = {
        options: {
          methods: ['card', 'wallets', 'local_methods'],
          methods_disabled: [],
          card_icons: ['mastercard', 'visa'],
          active_tab: 'card',
          default_country: 'UA',
          countries: ['PL', 'IE', 'GB', 'CZ', 'GE', 'RU'],
          fields: false,
          // title: 'title',
          link: 'https://oculisa.com',
          full_screen: true,
          button: true,
          locales: [
            'cs',
            'de',
            'en',
            'es',
            'fr',
            'hu',
            'it',
            'ko',
            'lv',
            'pl',
            'ro',
            'ru',
            'sk',
            'uk',
          ],
          email: true,
        },
        params: {
          merchant_id: 1456294,
          order_id: orderId,
          server_callback_url: 'https://syyvv2oi37.execute-api.eu-central-1.amazonaws.com/stage/order/callback',
          email,
          required_rectoken: 'y',
          currency: currency,
          amount: Math.round(amount * 100),
          order_desc: `${leads} leads`,
          // response_url: `${protocol}//${host}/payment-and-balance?sucess`,
        },
        messages: {
          en: {
            card_number: 'Card number',
            my_title: 'Order description',
          },
        },
      };

      window.fondy('#fondy-pay', Options);
    };

    const link = document.createElement('link');

    link.href = 'https://pay.fondy.eu/latest/checkout-vue/checkout.css';
    link.rel = 'stylesheet';

    document.body.append(script);

    document.body.append(link);
  }, [amount, currency, leads]);

  if (!orderId) {
    alert('No order id');
  }

  return <div id="fondy-pay"></div>;
}

export default withRouter(PayModal);
