import React, { Component } from 'react';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import OculisaTextField from '../Common/OculisaTextField';
import { withTranslation } from 'react-i18next';
import * as api from 'api';
import { toast } from 'react-toastify';

const closeIcon = require('../../assets/images/close-icon.svg');

class RequestIntegrationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      comments: '',
      leadCount: 'lessThan200',
    };
  }

  requestForIntegration = async () => {
    const { name, email, site, comments } = this.state;
    if (!email) {
      toast.error('Enter email');
      return;
    }
    try {
      await api.requestForIntegration({
        clientName: name,
        clientEmail: email,
        clientDomain: site,
        leadCount: 'lessThan200',
        comments,
      });

      window.dataLayer.push({ event: 'request-integration'});
      toast.success('Success!');
      this.props.toggleIntegrationModal();
      this.setState({
        name: '',
        email: '',
        comments: '',
        leadCount: 'lessThan200',
      });
    } catch (error) {
      console.dir(error);
      toast.error(error.response.data.message);
    }
  };

  render() {
    const { integrationModalOpen, toggleIntegrationModal } = this.props;

    return (
      <Modal
        className="request-integration"
        show={integrationModalOpen}
        onHide={toggleIntegrationModal}
        backdropClassName="custom-modal-backdrop"
        dialogClassName="oculisa-custom-modal"
      >
        <Modal.Header>
          <Modal.Title className="modal-title">{this.props.t('requestAnIntegration')}</Modal.Title>
          <div className="close-button-container" onClick={toggleIntegrationModal}>
            <img src={closeIcon} alt="Close modal icon" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <OculisaTextField
                label={this.props.t('name')}
                value={this.state.name}
                type="name"
                autoComplete="name"
                onChange={(e) => this.setState({ name: e })}
              />
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} sm={12} md={6} lg={6}>
                <OculisaTextField
                  label={this.props.t('contactEmail')}
                  value={this.state.email}
                  type="email"
                  autoComplete="email"
                  onChange={(e) => this.setState({ email: e })}
                />
              </Form.Group>

              <Form.Group as={Col} sm={12} md={6} lg={6}>
                <OculisaTextField
                  label={this.props.t('domainName')}
                  value={this.state.site}
                  onChange={(e) => this.setState({ site: e })}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm={12} md={4} lg={4}>
                <p className="radio-label">{this.props.t('requestAverageAmount')}</p>
              </Form.Group>
              <Form.Group as={Col} sm={12} md={8} lg={8} className="checkboxes">
                {[
                  {
                    value: 'lessThan200',
                    count: 200,
                    textI18n: 'requestLess',
                  },
                  {
                    value: 'lessThan800',
                    count: 800,

                    textI18n: 'requestLess',
                  },
                  {
                    value: 'lessThan1500',
                    count: 1500,

                    textI18n: 'requestLess',
                  },
                  {
                    value: 'moreThan1500',
                    count: 1500,
                    textI18n: 'requestMore',
                  },
                ].map((item) => {
                  const { value, count, textI18n } = item;
                  return (
                    <div className="inp-radio--block" type="radio" key={value}>
                      <input
                        type="radio"
                        id={value}
                        isValid
                        checked={this.state.leadCount === value}
                        onClick={() => this.setState({ leadCount: value })}
                      />

                      <label htmlFor={value}>
                        {this.props.t(textI18n)} {count}
                      </label>
                    </div>
                  );
                })}

                {/* <Form.Check className="inp-radio--block" type="radio" id={'800'}>
                      <Form.Check.Input type="radio" className="inp-radio" isValid />
                      <span className="checkmark"></span>
                      <Form.Check.Label>{this.props.t('requestLess')} 800</Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                  <Form.Group as={Col} className="no-margin-bottom">
                    <Form.Check className="inp-radio--block" type="radio" id={'1500'}>
                      <Form.Check.Input type="radio" className="inp-radio" isValid />
                      <span className="checkmark"></span>
                      <Form.Check.Label>{this.props.t('requestLess')} 1500</Form.Check.Label>
                    </Form.Check>
                    <Form.Check className="inp-radio--block" type="radio" id={'above-all'}>
                      <Form.Check.Input type="radio" className="inp-radio" isValid />
                      <span className="checkmark"></span>
                      <Form.Check.Label>{this.props.t('requestMore')} 1500</Form.Check.Label>
                    </Form.Check>
                  </Form.Group> */}
                {/* </Form.Row> */}
              </Form.Group>
            </Form.Row>
            <Form.Group className="comments">
              <Form.Label>{this.props.t('requestComments')}</Form.Label>
              <Form.Control
                as="textarea"
                style={{ height: 154 }}
                className="oculisa-textfield textarea"
              />
            </Form.Group>
            <Form.Group style={{ textAlign: 'center', marginBottom: 0 }}>
              <Button
                size="lg"
                className="purple-button modal-submit-btn"
                onClick={this.requestForIntegration}
              >
                <h3 className="modal-submit no-margin-bottom">{this.props.t('submit')}</h3>
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withTranslation()(RequestIntegrationModal);
