import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import ContactUsModal from '../Common/ContactUsModal';
import { useTranslation, initReactI18next } from 'react-i18next';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';
import { ModalContext } from 'context/modal';

const oculisaLogo = require('../../assets/images/logo.svg');
const worldLogo = require('../../assets/images/world-icon.svg');

const LoggedInFooter = () => {
  const { t } = useTranslation();
  const [isContactUsOpen, toggleContactUs] = useState(false);

  const openModal = useContext(ModalContext);

  return (
    <footer className="container">
      <hr className="footer-hr" />
      <div className="oculisa-footer flex-container login-footer">
        <div className="footer-logo flex-container">
          <Link to="/">
            <img src={oculisaLogo} alt="aculisa logo" />
          </Link>
          <div className="login-copyright-text">
            <p>
              © 2020 Oculisa. <br /> All rights reserved.
            </p>
          </div>
          <Navbar>
            <Navbar.Brand className="logged-navbar-brand">
              <LanguageSwitcher withIcon={true} />
            </Navbar.Brand>
          </Navbar>
        </div>
        <div>
          <Link to="/terms-of-service" className="logged-in-footer-links">
            {t('termsOfService')}
          </Link>
          <Link to="/privacy-policy" className="logged-in-footer-links">
            {t('privacyPolicy')}
          </Link>
          <a
            className="logged-in-footer-links last"
            href="#contactus"
            onClick={(e) => {
              // use <button>
              e.preventDefault();
              openModal('contact-us');
            }}
          >
            <span className="link">{t('contactUs')}</span>
          </a>
        </div>
      </div>

      {isContactUsOpen && (
        <ContactUsModal isOpen={isContactUsOpen} toggle={() => toggleContactUs(!isContactUsOpen)} />
      )}
    </footer>
  );
};

export default LoggedInFooter;
