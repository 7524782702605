import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'components/Common';
import { useTranslation, initReactI18next } from 'react-i18next';

const empowerSales = require('../../assets/images/empower-sales.svg');

function EmpowerSales() {
  const { t } = useTranslation();
  return (
    <section className="homepage-section deep-analytics empower-sales">
      <Row>
        <Col className="banner-section-left" sm={12} md={5} lg={6}>
          <img src={empowerSales} alt="Deep analytics source" className="m-0" />
        </Col>
        <Col sm={12} md={7} lg={6} className="product__title--block">
          <div className="deep-analytics-text">
            <h2 className="header">{t('empowerSalesAi')}</h2>
            <h3 className="details">{t('empowerSalesAiDescription')}</h3>
          </div>
        </Col>
      </Row>
      <Row className="artificial__intelligence--row">
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('empowerSalesScoring')}</p>
          <p className="analytics-point-desc">{t('empowerSalesScoringDescription')}</p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('empowerSalesForecasting')}</p>
          <p className="analytics-point-desc">{t('empowerSalesForecastingDescription')}</p>
        </Col>
        <Col className="analytics-points" xs={6} md={4} lg={3}>
          <p className="analytics-point-header">{t('empowerAdvices')}</p>
          <p className="analytics-point-desc">{t('empowerAdvicesDescription')}</p>
        </Col>
        <Col className="analytics-points text-center text-sm-center" sm={12} lg={3}>
          <Button to="/get-started" className="get-started-button">
            {t('getStarted')}
          </Button>
        </Col>
      </Row>
    </section>
  );
}

export default EmpowerSales;
