import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.scss';
import { BrowserRouter } from 'react-router-dom';

import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from '../node_modules/redux';
import reducers from 'redux/index';
import thunk from 'redux-thunk';
import Amplify from 'aws-amplify';

import 'react-toastify/dist/ReactToastify.min.css';

const GTM_KEY = process.env.REACT_APP_GTM_KEY;

GTM_KEY && TagManager.initialize({
  gtmId: GTM_KEY,
})


const composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composer(applyMiddleware(thunk)));

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
  },
});



ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
