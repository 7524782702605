import React from 'react';
import CommentBox from './CommentBox';
import { Link } from 'react-router-dom';
import StatusSteps from './StatusSteps';
import { useTranslation, initReactI18next } from 'react-i18next';

const LeadItemDesktop = ({ lead, updateLead, deleteLead }) => {
  const { t } = useTranslation();
  const {
    name,
    lead_status: status,
    phone,
    predicted_probability,
    date,
    time,
    lead_id,
    email,
    comment,
    photo,
    address,
    job,
  } = lead;

  async function update(data) {
    await updateLead(lead_id, data);
  }

  return (
    <div className="leads-item-container desktop">
      <div className="user-details">
        <div className="details-l">
          <img className="leads-avatar" src={
                   ( photo && photo !== 'None') ?   photo :
                    'https://www.deadline.com.ua/design/img/default-avatar.png'
                  } alt="Leads avatar" />
        </div>
        <div className="details-r">
          <div className="details-rt probability">
            <p className="text">
              {t('leadDealProbability')}{' '}
              <span style={{ fontWeight: 'bold', color: '#623695' }}>{predicted_probability}%</span>
            </p>
            <p className="text padding-left">
              {date} <span style={{ color: '#8C8C8C' }}>{time}</span>
            </p>
          </div>
          <div className="details-rb">
            <div style={{ flexGrow: 1 }}>
              <div className="two-row">
                <p className="label">{t('leadName')}</p>
                <p className="text">{name}</p>
              </div>
              <div className="two-row">
                <p className="label">{t('leadPhone')}</p>
                <p className="text">{phone}</p>
              </div>
              <div className="two-row">
                <p className="label">{t('leadEmail')}</p>
                <p className="text">{email}</p>
              </div>
              <div className="two-row">
                <p className="label">{t('leadAdress')}</p>
                <p className="text">{address}</p>
              </div>
              <div className="two-row">
                <p className="label">{t('leadPosition')}</p>
                <p className="text">{job}</p>
              </div>
              <div className="actions d-none">
                <Link className="btn action-button" to="/lead-profile/12345">
                  <i className="fa fa-user-o" aria-hidden="true" />
                  <span>{t('leadViewProfile')}</span>
                </Link>
                <button
                  className="btn action-button"
                  // onClick={() => {
                  //   deleteLead(lead_id);
                  // }}
                >
                  <i className="fa fa-trash" aria-hidden="true" />
                  <span>{t('leadDelete')}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="comments">
        <p className="status-heading">{t('leadStatus')}</p>
        <StatusSteps status={status} updateLead={update} />
        <CommentBox updateLead={update} comment={comment} />
      </div>
      <div className="actions">
        <Link className="btn action-button" to={`/lead-profile/${lead_id}`}>
          <i className="fa fa-user-o" aria-hidden="true" />
          <span>{t('leadViewProfile')}</span>
        </Link>
        <button
          className="btn action-button"
          onClick={() => {
            deleteLead(lead_id);
          }}
        >
          <i className="fa fa-trash" aria-hidden="true" />
          <span>{t('leadDelete')}</span>
        </button>
      </div>
    </div>
  );
};

export default LeadItemDesktop;
