import React, { useState, useContext } from 'react';
import { Form, Col } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import OculisaTextField from '../../components/Common/OculisaTextField';
import { Button } from 'components/Common';
import { connect } from 'react-redux';
import { auth } from 'redux/user/actions';
import { toast } from 'react-toastify';
import { useTranslation, initReactI18next } from 'react-i18next';

import { Auth } from 'aws-amplify';

import { ModalContext } from 'context/modal';
import { isEmpty } from 'lodash';

const Signup = ({ history, auth }) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState({
    email: sessionStorage.getItem('registration-email') || '',
    password: '',
    password2: '',
  });

  const [errors, setErrors] = useState({});

  const { email, password, password2 } = form;

  function handleChange(value, name) {
    setForm({
      ...form,
      [name]: value,
    });
    // validate();
  }

  function validate() {
    const e = {};

    // if(email.substr(email.indexOf('.', email.indexOf('@'))).length === 1) {
    //   e.email = 'Wrong format for the email';
    // }

    if (password.length < 8) {
      e.password = 'Short password';
    }

    if (password2.length < 8) {
      e.password2 = 'Short password';
    }

    if (!password) {
      e.password = 'Required';
    }

    if (!password2) {
      e.password2 = 'Required';
    }

    if (password !== password2) {
      e.password = 'Passwords should match';
      e.password2 = 'Passwords should match';
    }

    if (!isEmpty(e)) {
      setErrors(e);
      return false;
    }
    return true;
  }

  async function submit(event) {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    setLoading(true);

    try {
      const response = await Auth.signUp(form.email, form.password);
      toast.success('Check your email!');
      setForm({});
    } catch (e) {
      alert(e.message);
    }

    setLoading(false);
  }

  // async function handleConfirmationSubmit ( event)  {
  //   event.preventDefault();

  //   setLoading(true);

  //   try {
  //     const response = await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
  //     const response2 = await Auth.signIn(form.email, form.password);

  //     this.props.auth(response2.signInUserSession.accessToken.jwtToken);

  //     this.props.history.push("/personal-cabinet");
  //   } catch (e) {
  //     alert(e.message);
  //   }

  //   setLoading(false);

  // }

  const openModal = useContext(ModalContext);

  return (
    <div className="container get-started-page">
      <div className="get-started-content">
        <h3 className="heading-text">{t('signUpTitile')}</h3>
        <p className="heading-desc">{t('signUpTitleDescription')}</p>
        <Form className="sign-up-form" onSubmit={submit}>
          <Form.Group>
            <OculisaTextField
              label={t('email')}
              value={email}
              type="email"
              error={errors.email}
              name="email"
              autoComplete="email"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col} sm={12} md={6} lg={6}>
              <OculisaTextField
                label={t('password')}
                value={password}
                type="password"
                name="password"
                autoComplete="new-password"
                error={t(errors.password)}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} sm={12} md={6} lg={6}>
              <OculisaTextField
                label={t('confirmPass')}
                value={password2}
                type="password"
                name="password2"
                error={errors.password2}
                onChange={handleChange}
              />
            </Form.Group>

            <p className="conditions">
              {t('signUpDescription')}
              <button
                onClick={event => {
                  event.preventDefault();
                  openModal('terms');
                }}
              >
                {t('signUpTerms')}.
              </button>
              {t('signUpDescription2')}{' '}
              <button
                onClick={event => {
                  event.preventDefault();
                  openModal('privacy');
                }}
              >
                {t('signUpPolicy')}.
              </button>
              {t('signUpDescription3')}
            </p>

            <Form.Group as={Col} className="flex-container bottom-buttons">
              <Link to="/login" className="sign-up-btn take-full-width">
                {t('alreadyHaveAccount')}
              </Link>
              <Button
                size="lg"
                type="submit"
                className="modal-submit-btn take-full-width"
                disabled={isLoading}
              >
                {t('getLeadsFree')}
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    </div>
  );
};

export default withRouter(connect(null, { auth })(Signup));
