import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class DefaultLayout extends Component {
  componentWillUpdate() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="default-layout">
        <Header location={this.props.location} />
        <div>{this.props.children}</div>
        <Footer />
      </div>
    );
  }
}

export default DefaultLayout;
