import React from 'react';
import { Form, Col } from 'react-bootstrap';

import './CheckEmail.scss';

import PropTypes from 'prop-types';

const data = {
  confirm: {
    title: 'Confirm your Email',
    text: 'A confirmation email has been sent to your email:',
  },
  reset: {
    title: 'Check your Email',
    text: 'A reset link has been sent to your email: ',
  },
};

const CheckEmail = ({ email, type }) => {
  const { title, text } = data[type];
  return (
    <Form className="check-email">
      <h4 className="heading-text">{title}</h4>
      <p>
        {text}

        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`//${email.slice(email.indexOf('@') + 1, email.length)}`}
        >
          {email}
        </a>
      </p>
    </Form>
  );
};

CheckEmail.propTypes = {};

export default CheckEmail;
