import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'components/Common';
import { useTranslation, initReactI18next } from 'react-i18next';

const deepAnalytics = require('../../assets/images/deep-analytics.svg');

function DeepAnalytics() {
  const { t } = useTranslation();
  return (
    <section className="homepage-section deep-analytics" id="deep-analytics">
      <Row>
        <Col className="banner-section-left pl-sm-0" sm={12} md={5} lg={6}>
          <img src={deepAnalytics} alt="Deep analytics source" className="deep__analytics__img" />
        </Col>
        <Col sm={12} md={7} lg={6} className="product__title--block">
          <div className="deep-analytics-text">
            <h2 className="header">{t('analiticsTitle')}</h2>
            <h3 className="details">{t('analiticsDescription')}</h3>
          </div>
        </Col>

        <Row className="m-0">
          <Col className="analytics-points" xs={6} md={4} lg={3}>
            <p className="analytics-point-header">{t('analiticsFirstVisit')}</p>
            <p className="analytics-point-desc">{t('analiticsFirstVisitDescription')}</p>
          </Col>
          <Col className="analytics-points" xs={6} md={4} lg={3}>
            <p className="analytics-point-header">{t('analiticsEndVisit')}</p>
            <p className="analytics-point-desc">{t('analiticsEndVisitDescription')}</p>
          </Col>
          <Col className="analytics-points" xs={6} md={4} lg={3}>
            <p className="analytics-point-header">{t('analiticsCreationRequest')}</p>
            <p className="analytics-point-desc">{t('analiticsCreationRequestDescription')}</p>
          </Col>
          <Col className="analytics-points" xs={6} md={4} lg={3}>
            <p className="analytics-point-header">{t('analiticsBrowserInfo')}</p>
            <p className="analytics-point-desc">{t('analiticsBrowserInfoDescription')}</p>
          </Col>
          <Col className="analytics-points" xs={6} md={4} lg={3}>
            <p className="analytics-point-header">{t('analiticsDevice')}</p>
            <p className="analytics-point-desc">{t('analiticsDeviceDescription')}</p>
          </Col>
          <Col className="analytics-points" xs={6} md={4} lg={3}>
            <p className="analytics-point-header">{t('analiticsLocation')}</p>
            <p className="analytics-point-desc">{t('analiticsLocationDescription')}</p>
          </Col>
          <Col className="analytics-points" xs={6} md={4} lg={3}>
            <p className="analytics-point-header">{t('analiticsPagesHistory')}</p>
            <p className="analytics-point-desc">{t('analiticsPagesHistoryDescription')}</p>
          </Col>
          <Col className="analytics-points" xs={6} md={4} lg={3}>
            <p className="analytics-point-header">{t('analiticsTraffic')}</p>
            <p className="analytics-point-desc">{t('analiticsTrafficDescription')}</p>
          </Col>
          <Col className="analytics-points" xs={6} md={4} lg={3}>
            <p className="analytics-point-header">{t('analiticsVisits')}</p>
            <p className="analytics-point-desc">{t('analiticsVisitsDescription')}</p>
          </Col>
          <Col className="analytics-points" xs={6} md={4} lg={3}>
            <p className="analytics-point-header">{t('analiticsTime')}</p>
            <p className="analytics-point-desc">{t('analiticsTimeDescription')}</p>
          </Col>
          <Col className="analytics-points" xs={6} md={4} lg={3}>
            <p className="analytics-point-header">{t('analiticsEvents')}</p>
            <p className="analytics-point-desc">{t('analiticsEventsDescription')}</p>
          </Col>
          <Col className="analytics-points" xs={6} md={4} lg={3}>
            <p className="analytics-point-header">{t('analiticsShopping')}</p>
            <p className="analytics-point-desc">{t('analiticsShoppingDescription')}</p>
          </Col>
        </Row>
        <Row className="analytics__bottom--row m-0">
          <Col className="analytics-points" sm={12} md={4} lg={6}>
            <p className="more-factors-text">+11 {t('outherFactors')}</p>
          </Col>
          <Col className="analytics-points text-center text-md-right" sm={12} md={4} lg={6}>
            <Button to="/get-started" className="get-started-button">
              {t('getStarted')}
            </Button>
          </Col>
        </Row>
      </Row>
    </section>
  );
}

export default DeepAnalytics;
