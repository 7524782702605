import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation, initReactI18next } from 'react-i18next';

const CommentBox = ({ updateLead, comment: com }) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [comment, setComment] = useState(com);
  const inputRef = useRef();

  useEffect(() => {
    if (edit) {
      inputRef.current.focus();
    }
  }, [edit]);

  const onBlur = () => {
    setEdit(false);
    update(comment);
  };

  async function update(comment) {
    await updateLead({
      comment,
    });
  }

  return (
    <Form.Group className="comment-form-container">
      <Form.Control
        ref={inputRef}
        as="textarea"
        placeholder={t('comment')}
        value={comment}
        onBlur={onBlur}
        onChange={e => setComment(e.target.value)}
        style={{ height: 97 }}
        disabled={!edit}
        className="oculisa-textfield textarea"
      />
      {!edit && (
        <div onClick={() => setEdit(true)} className="edit-button clickable">
          <span className="icon-pencil" />
        </div>
      )}
    </Form.Group>
  );
};

export default CommentBox;
