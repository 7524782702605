import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// const actorsLogo = require("../../assets/images/actors.svg");
// const actorsLogoTablet = require("../../assets/images/tablet-actors.svg");

import { useTranslation, initReactI18next } from 'react-i18next';

import People from './People/People';

function BannerSection() {
  const { t } = useTranslation();

  return (
    <section className="banner-section banner-1 main__page">
      <Row>
        <Col sm={12} md={6} lg={5} className="banner-1-left main-page-text">
          <h1 className="focus-text">{t('landingMainTitle')}</h1>
          <h4 className="boost-text">{t('landingMainDescription')}</h4>
          <Link
            to="/get-started"
            className="btn purple-button purple-button-big valuable-leads--btn"
          >
            {t('getLeadsFree')}
          </Link>
          <div style={{ marginTop: 20 }}>
            <h5 className="connect-text">{t('сonnectDescription')}</h5>
            <h5 className="connect-text">{t('noCredit')}</h5>
          </div>
        </Col>
        <Col sm={12} md={6} lg={7}>
          {/* <img src={actorsLogo} className="actors-svg" alt="Actors svg" />
          <img
            src={actorsLogoTablet}
            className="actors-svg-tablet"
            alt="Actors svg"
          /> */}

          <People />
        </Col>
      </Row>
    </section>
  );
}

export default BannerSection;
