import React, { Component } from 'react';
import LoginFooter from '../../components/Footer/LoginFooter';

class LoginLayout extends Component {
  render() {
    return (
      <div className="LoginLayout">
        <div style={{ minHeight: '80vh' }}>{this.props.children}</div>
        <LoginFooter />
      </div>
    );
  }
}

export default LoginLayout;
