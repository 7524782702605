export const prices = [
  {
    i18nText: 'priceStartup',
    leadsTill: 200,
    prices: {
      usd: 1.99,
      uah: 49.99,
      rub: 128.99,
    },
  },
  {
    i18nText: 'priceGrowth',
    leadsTill: 800,
    discount: 25,
    prices: {
      usd: 1.49,
      uah: 37.49,
      rub: 96.99,
    },
  },
  {
    i18nText: 'priceEnterprise',
    leadsTill: 1499,
    discount: 50,
    prices: {
      usd: 0.99,
      uah: 24.99,
      rub: 63.99,
    },
  },
  {
    name: 'corporate',
    i18nText: 'priceCorporate',
    leadsTill: 1500,
  },
];

export const currencySymbols = {
  usd: '$',
  uah: '₴',
  rub: '₽',
};
