import React, { Component } from 'react';
import { Button, Form, Col } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import OculisaTextField from '../../components/Common/OculisaTextField';
import { Auth } from 'aws-amplify';
import { ModalContext } from 'context/modal';
import { connect } from 'react-redux';
import { auth } from 'redux/user/actions';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

const oculisaLogo = require('../../assets/images/logo.svg');
const closeIcon = require('../../assets/images/close-icon.svg');

class Login extends Component {
  constructor(props) {
    super(props);


    this.state = {
      email: '',
      password: '',

      errors: {},
    };

    this.onClose = this.onClose.bind(this);
    this.onLogin = this.onLogin.bind(this);
  }

  onClose() {
    this.props.history.goBack();
  }

  validate = () => {
    const errors = {};
    const { email, password } = this.state;

    if (!password) {
      errors.password = this.props.t('fieldRequired');
    }

    if (!email) {
      errors.email = this.props.t('fieldRequired');
    }

    if (!isEmpty(errors)) {
      this.setState({ errors });
      return false;
    } else {
      return true;
    }
  };

  async onLogin() {
    if (!this.validate()) {
      return;
    }

    try {
      const response = await Auth.signIn(this.state.email, this.state.password);
      this.props.auth(response.signInUserSession.accessToken.jwtToken);
      this.props.history.push('/personal-cabinet');
    } catch (e) {
      alert(e.message);
    }
  }

  handleChange = (value, name) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <div className="container login-page">
        <div className="centre-alligned-content">
          <img src={oculisaLogo} alt="oculisa logo" />
          <div className="login-form-container">
            <div className="header">
              <h3 className="login-title">{this.props.t('logIn')}</h3>
              <div className="close-button-container clickable" onClick={this.onClose}>
                <img src={closeIcon} alt="Close modal icon" />
              </div>
            </div>
            <Form>
              <Form.Group>
                <OculisaTextField
                  label={this.props.t('email')}
                  name="email"
                  autoComplete="email"
                  value={this.state.email}
                  error={this.state.errors.email}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group>
                <OculisaTextField
                  label={this.props.t('yourPassword')}
                  name="password"
                  type="password"
                  autoComplete="password"
                  value={this.state.password}
                  error={this.state.errors.password}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col}>
                  <Button
                    size="lg"
                    className="purple-button modal-submit-btn take-full-width"
                    onClick={this.onLogin}
                  >
                    <h3 className="modal-submit no-margin-bottom">{this.props.t('logIn')}</h3>
                  </Button>
                </Form.Group>

                <Form.Group as={Col}>
                  <Link to="/get-started" className="sign-up-btn take-full-width">
                    {this.props.t('signUp')}
                  </Link>
                </Form.Group>
              </Form.Row>
              <div style={{ textAlign: 'center' }}>
                <Link to="/reset" onClick={(e) => {
                  this.context('contact-us');
                  e.preventDefault();
                }} className="sign-up-btn take-full-width">
                  {this.props.t('passwordForgot')}
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

Login.contextType = ModalContext;

export default withTranslation()(
  connect(
    null,
    { auth }
  )(withRouter(Login))
);
