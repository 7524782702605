import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import OculisaSwitchButton from '../Common/OculisaSwicthButton';
import classNames from 'classnames';
import { useTranslation, initReactI18next } from 'react-i18next';
import { ShepherdTourContext } from 'react-shepherd';

function SiteCardItem({ isChecked, onEdit, site, onCheck, isTutorial }) {
  const { t } = useTranslation();

  const tour = useContext(ShepherdTourContext);

  useEffect(() => {
    if (isTutorial) {
      // tour.addStep({
      //   attachTo: { element: '.site-card', on: 'bottom' },
      //   // title: 'Connect your website',
      //   id: 'sas2',
      //   modalOverlayOpeningPadding: 5,
      //   buttons: [
      //     {
      //       classes: 'shepherd-button-secondary',
      //       text: 'Exit',
      //       // type: 'cancel',
      //       action() {
      //         return this.cancel();
      //       },
      //     },
      //     // {
      //     //   classes: 'shepherd-button-primary',
      //     //   text: 'Back',
      //     //   type: 'back',
      //     // },
      //     {
      //       classes: 'shepherd-button-primary',
      //       text: 'Next',
      //       action() {
      //         return this.next();
      //       },
      //     },
      //   ],
      //   text: 'Here you can see all requests processed by Oculisa and manage their status.',
      // });
    }

    // tour.show('sas2');
  }, [isTutorial, tour]);

  return (
    <div className="col-lg-4 col-md-6 col-sm-12">
      <div className="sites site-card">
        <div className="top flex-container">
          <p className="title">{site}</p>
          <OculisaSwitchButton isChecked={isChecked} onCheck={onCheck} />
        </div>
        <div className="actions">
          <Link
            to={`/leads/${site}`}
            className={classNames('btn action-pills', {
              active: isChecked,
              'not-active': !isChecked,
            })}
          >
            {t('dashboardManage')}
          </Link>
          <button
            onClick={onEdit}
            className={classNames('action-pills', {
              active: isChecked,
              'not-active': !isChecked,
            })}
          >
            {t('dashboardEdit')}
          </button>
        </div>
      </div>
    </div>
  );
}
export default SiteCardItem;
