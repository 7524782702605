import React, { useState } from 'react';
import classNames from 'classnames';

function OculisaSwitchButton({ isChecked, onCheck }) {
  // const [isChecked, toggleIsCheckted] = useState(isCheckedFromProps);
  return (
    <div onClick={onCheck} className="switch-container">
      <div
        className={classNames('switch', {
          on: isChecked,
          off: !isChecked,
        })}
      />
    </div>
  );
}

export default OculisaSwitchButton;
