import React from 'react';
import { Switch, Route } from 'react-router-dom';
import DefaultLayout from './containers/Layouts/DefaultLayout';
import LoginLayout from './containers/Layouts/LoginLayout';
import LoggedInLayout from './containers/Layouts/LoggedInLayout';
import Home from './containers/Home/Home';
import Product from './containers/Product/Product';
import Pricing from './containers/Pricing/Pricing';
import Login from './containers/Login/Login';
import Signup from './containers/GetStarted/Signup';
import Dashboard from './containers/Dashboard/Dashboard';
import LeadsDashboard from './containers/Dashboard/LeadsDashboard';
import CreateNewSite from './containers/NewSite/CreateNewSite';
import ProfileSettings from './containers/Settings/ProfileSettings';
import Payment from './containers/Payment/Payment';
import LeadProfile from './containers/Dashboard/LeadProfile';
import ResetPassword from './containers/ResetPassword/ResetPassword';
import NewPassword from './containers/ResetPassword/NewPassword/NewPassword';
import Privacy from 'components/Privacy/Privacy';
import Terms from 'components/Terms/Terms';
import PayModal from 'containers/Payment/PayModal/PayModal';

const routes = [
  {
    exact: true,
    path: '/',
    Component: Home,
    Layout: DefaultLayout,
  },
  {
    path: '/product',
    Component: Product,
    Layout: DefaultLayout,
  },
  {
    path: '/privacy-policy',
    Component: Privacy,
    Layout: DefaultLayout,
  },
  {
    path: '/terms-of-service',
    Component: Terms,
    Layout: DefaultLayout,
  },
  {
    path: '/pricing',
    Component: Pricing,
    Layout: DefaultLayout,
  },
  {
    path: '/login',
    Component: Login,
    Layout: LoginLayout,
  },
  {
    path: '/pay',
    Component: PayModal,
    Layout: LoggedInLayout,
  },
  {
    path: '/get-started',
    Component: Signup,
    Layout: DefaultLayout,
  },
  {
    path: '/reset',
    Component: ResetPassword,
    Layout: DefaultLayout,
  },
  {
    path: '/new-password',
    Component: NewPassword,
    Layout: DefaultLayout,
  },
  {
    path: '/personal-cabinet',
    Component: Dashboard,
    Layout: LoggedInLayout,
  },
  {
    path: '/leads/:site',
    Component: LeadsDashboard,
    Layout: LoggedInLayout,
  },
  {
    path: '/lead-profile/:leadId',
    Component: LeadProfile,
    Layout: LoggedInLayout,
  },
  {
    path: '/create-new-site',
    Component: CreateNewSite,
    Layout: LoggedInLayout,
  },
  {
    path: '/settings',
    Component: ProfileSettings,
    Layout: LoggedInLayout,
  },

  {
    path: '/payment-and-balance',
    Component: Payment,
    Layout: LoggedInLayout,
  },
];

export default function configRoutes() {
  return (
    <Switch>
      {routes.map((route, index) => {
        const { path, exact, Layout, Component } = route;
        return (
          <Route
            key={index}
            exact={exact}
            path={path}
            render={(props) => (
              <Layout {...props}>
                <Component {...props} />
              </Layout>
            )}
          />
        );
      })}
      <Route path="*">Not found page</Route>
    </Switch>
  );
}
