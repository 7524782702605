import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation, initReactI18next } from 'react-i18next';

const manageLeads = require('../../assets/images/manage-leads.svg');

function ManageLeads() {
  const { t } = useTranslation();

  return (
    <section className="homepage-section deep-analytics manage-leads">
      <Row>
        <Col sm={12} md={7} lg={6} className="product__title--block">
          <div className="deep-analytics-text">
            <h3 className="header">{t('manageLeads')}</h3>
            <p className="details">{t('manageLeadsDescription')}</p>
          </div>
        </Col>
        <Col className="banner-section-left" sm={12} md={5} lg={6}>
          <img src={manageLeads} alt="Manage leads source" />
        </Col>
      </Row>
    </section>
  );
}

export default ManageLeads;
