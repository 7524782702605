import axios from 'axios';

export function createWebsite(data) {
  return axios.post('/site', data);
}

export function getWebsites() {
  return axios.get('site?limit=100');
}

export function getWebsite(id) {
  return axios.get(`site/${id}`);
}

export function updateWebsite(id, data) {
  !data.trackedForms && (data.trackedForms = '');
  !data.unTrackedForms && (data.unTrackedForms = '');

  return axios.put(`site/${id}`, data);
}
