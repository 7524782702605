import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import OculisaTextField from '../../components/Common/OculisaTextField';
import { withTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { ShepherdTourContext } from 'react-shepherd';

class TechnicalSettings extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      includedCSSClasses: '',
      excludedCSSClasses: '',
      interactionEvents: '',
      ids: '',
      triggers: '',
      shoppingCart: '',
    };

    if (!this.props.editModal) {
      context.addStep({
        attachTo: { element: '.tech-settings', on: 'bottom' },
        modalOverlayOpeningPadding: 5,
        buttons: [
          {
            classes: 'button btn btn-secondary',
            text: this.props.t('tutorialButtonExit'),
            action() {
              return this.cancel();
            },
          },
          {
            classes: 'button btn purple-button',
            text: this.props.t('tutorialButtonBack'),
            action() {
              return this.back();
            },
          },
        ],
        text: this.props.t('tutorialTechicalSettings'),
      });
    }

    this.onSelectClientType = this.onSelectClientType.bind(this);
  }

  onSelectClientType(event) {
    event.preventDefault();
    const { id } = event.target;

    this.setState({
      clientType: id,
    });
  }

  handleChange = (value, name) => {
    this.props.handleChange(value, name);
  };

  handleCustomForm = (index) => (value, name) => {
    const arr = cloneDeep(this.props.customForms);
    arr[index][name] = value;

    const { trigger, form } = arr[index];

    if (!trigger && !form && arr.length > 1) {
      arr.splice(index, 1);
    }

    const lastItem = arr[arr.length - 1];

    if (lastItem.form && lastItem.trigger) {
      arr.push({ form: '', trigger: '' });
    }

    this.props.handleChange(arr, 'customForms');
  };

  render() {
    const { editModal } = this.props;
    const {
      includedCSSClasses,
      excludedCSSClasses,
      interactionEvents,
      ids,
      triggers,
      shoppingCart,
    } = this.state;

    return (
      <div
        className={classNames('section-step-form', 'tech-settings', {
          'no-padding': editModal,
        })}
      >
        <Form className="sign-up-form">
          <a href="/pdf/instructions.pdf" target="_blank" className="link">
            {this.props.t('techSettings')}
          </a>
          <Form.Group className="technical-setting-form-item">
            <Form.Label>
              {this.props.t('techTrackForms')}
              <div>
                <small>{this.props.t('techTrackFormsDescription')}</small>
              </div>
            </Form.Label>
            <OculisaTextField
              label={this.props.t('techTrackFormsPlaceholder')}
              value={this.props.trackedForms}
              error={this.props.errors.trackedForms}
              style={{ width: '100%', marginTop: 7 }}
              name="trackedForms"
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group className="technical-setting-form-item">
            <Form.Label>{this.props.t('techIgnoreForms')}</Form.Label>
            <OculisaTextField
              label={this.props.t('techIgnoreFormsPlaceholder')}
              value={this.props.unTrackedForms}
              error={this.props.errors.unTrackedForms}
              name="unTrackedForms"
              style={{ width: '100%', marginTop: 7 }}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group className="technical-setting-form-item">
            <Form.Label>{this.props.t('techEvents')}</Form.Label>
            <OculisaTextField
              label={this.props.t('techEventsPlaceholder')}
              as="textarea"
              rows={2}
              style={{ marginTop: 7 }}
              value={this.props.interactiveEvents}
              error={this.props.errors.interactiveEvents}
              name="interactiveEvents"
              onChange={this.handleChange}
              className="oculisa-textfield textarea"
            />
          </Form.Group>

          <Form.Group className="technical-setting-form-item custom-forms">
            <Form.Label>
              {this.props.t('techCustomForms')}
              <div>
                <small>{this.props.t('techCustomFormsDescription')}</small>
              </div>
            </Form.Label>
          </Form.Group>

          {this.props.customForms.map((item, i) => {
            return (
              <Form.Group className="technical--settings-form-row" key={i}>
                <div className="technical--settings--item">
                  <span className="technical--settings--title">{this.props.t('techFormID')}</span>
                  <OculisaTextField
                    label="#id"
                    value={item.form}
                    name="form"
                    onChange={this.handleCustomForm(i)}
                  />
                </div>
                <div className="technical--settings--item">
                  <span className="technical--settings--title">
                    {this.props.t('techFormTrigger')}
                  </span>
                  <OculisaTextField
                    label="#trigger"
                    value={item.trigger}
                    name="trigger"
                    onChange={this.handleCustomForm(i)}
                  />
                </div>
              </Form.Group>
            );
          })}

          <Form.Group className="technical-setting-form-item">
            <Form.Label>{this.props.t('techShoppingCartText')}</Form.Label>
            <p>{this.props.t('techShoppingCartDescription')}</p>
            <OculisaTextField
              label={`${this.props.t('techShoppingCartPlaceholder')}: [{key: "cart",
              dataMap: {
                initSelector: "article.cart",
                dataSelectors: {
                  itemHeader: ".cart-section-heading",
                  items: {
                    product: "h5.product-title ",
                    cost: "div.product-price"
                  },
                  cartPrices: {
                    label: ".cart-priceItem-label",
                    value: ".cart-priceItem-value"
                  }
                }
              },
              params: {
                keepLineBreaks: false
              }}]`}
              value={this.props.cartSettings}
              style={{ width: '100%', marginTop: 7 }}
              name="cartSettings"
              onChange={this.handleChange}
            />
          </Form.Group>
        </Form>
      </div>
    );
  }
}

TechnicalSettings.contextType = ShepherdTourContext;

export default withTranslation()(TechnicalSettings);
