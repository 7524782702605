import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation, initReactI18next } from 'react-i18next';

const connectLogo = require('../../assets/images/connect-logo.svg');
const expandLogo = require('../../assets/images/expand-logo.svg');
const predictLogo = require('../../assets/images/predict-logo.svg');
const manageLogo = require('../../assets/images/manage-logo.svg');
const getNotifiedLogo = require('../../assets/images/get-notified-logo.svg');
const groupSvg = require('../../assets/images/how-it-works-group.svg');
const rightArrowLogo = require('../../assets/images/right-arrow.svg');
const leftArrowLogo = require('../../assets/images/left-arrow.svg');
const curledArrowLogo = require('../../assets/images/curled-arrow.svg');

function ExploreSection() {
  const { t } = useTranslation();
  return (
    <div className="homepage-section how-it-works-section">
      <h2 className="header">{t('howItWorks')}</h2>
      <Row style={{ marginTop: 30 }} className="how-it-works--block">
        <Col className="how-it-works-cols" sm={12} md={4}>
          <div className="logo-container how-it-works-img__block">
            <div>
              <img src={connectLogo} className="inside-icon" alt="Connect logo" />
            </div>
            <img src={groupSvg} className="group-svg" alt="Group Svg" />
          </div>
          <div className="how-it-works-text">
            <p className="heading">1. {t('connect')} </p>
            <p className="details">{t('howConnect')}</p>
          </div>
          <div className="right-arrow-div">
            <img src={rightArrowLogo} alt="Right arrow" />
          </div>
        </Col>
        <Col className="how-it-works-cols" sm={12} md={4}>
          <div className="logo-container how-it-works-img__block">
            <div>
              <img src={expandLogo} className="inside-icon" alt="Connect logo" />
            </div>
            <img src={groupSvg} className="group-svg" alt="Group Svg" />
          </div>
          <div className="how-it-works-text">
            <p className="heading">2. {t('expand')}</p>
            <p className="details">{t('howExpand')}</p>
          </div>
          <div className="right-arrow-div">
            <img src={rightArrowLogo} alt="Right arrow" />
          </div>
        </Col>
        <Col className="how-it-works-cols" sm={12} md={4}>
          <div className="logo-container how-it-works-img__block">
            <div>
              <img src={predictLogo} className="inside-icon" alt="Connect logo" />
            </div>
            <img src={groupSvg} className="group-svg" alt="Group Svg" />
          </div>
          <div className="how-it-works-text">
            <p className="heading">3. {t('predict')}</p>
            <p className="details">{t('howPredict')}</p>
          </div>
          <div className="curled-arrow-div">
            <img src={curledArrowLogo} alt="Right arrow" />
          </div>
        </Col>
        <Col className="how-it-works-cols" sm={12} md={{ span: 4, offset: 4 }}>
          <div className="logo-container how-it-works-img__block">
            <div>
              <img src={manageLogo} className="inside-icon" alt="Connect logo" />
            </div>
            <img src={groupSvg} className="group-svg" alt="Group Svg" />
          </div>
          <div className="how-it-works-text">
            <p className="heading">5. {t('manage')}</p>
            <p className="details">{t('howManage')}</p>
          </div>
          <div className="right-arrow-div">
            <img src={leftArrowLogo} alt="Right arrow" />
          </div>
        </Col>
        <Col className="how-it-works-cols" sm={12} md={4}>
          <div className="logo-container how-it-works-img__block">
            <div>
              <img src={getNotifiedLogo} className="inside-icon" alt="Connect logo" />
            </div>
            <img src={groupSvg} className="group-svg" alt="Group Svg" />
          </div>
          <div className="how-it-works-text">
            <p className="heading">4. {t('getNotified')}</p>
            <p className="details">{t('howNotified')}</p>
          </div>
        </Col>
      </Row>
      {/* <div className="grid-container">
        <div className="grid-item item-1">
          <div className="logo-container">
              <div>
                <img src={connectLogo} className="inside-icon" alt="Connect logo" />
              </div>
              <img src={groupSvg} className="group-svg" alt="Group Svg"/>
            </div>
            <p className="heading">1. Connect</p>
            <p className="details">Easily connect your site with our product</p>
          </div>
        <div className="grid-item">
          <div className="logo-container">
            <div>
              <img src={expandLogo} className="inside-icon" alt="Connect logo" />
            </div>
            <img src={groupSvg} className="group-svg" alt="Group Svg"/>
          </div>
          <p className="heading">2. Expand</p>
          <p className="details">Your leads data automatically expands up to 60+ factors</p>
        </div>
        <div className="grid-item">
          <div className="logo-container">
            <div>
              <img src={predictLogo} className="inside-icon" alt="Connect logo" />
            </div>
            <img src={groupSvg} className="group-svg" alt="Group Svg"/>
          </div>
          <p className="heading">3. Predict</p>
          <p className="details">Our AI gives a deal prediction, lead score and sales recommendations</p>
        </div>
        <div className="grid-item">4</div>
        <div className="grid-item">5</div>
      </div> */}
    </div>
  );
}

export default ExploreSection;
