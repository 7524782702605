import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import RequestIntegrationModal from '../Header/RequestIntegrationModal';
import { ModalContext } from 'context/modal';
import { useTranslation, initReactI18next } from 'react-i18next';

const oculisaLogo = require('../../assets/images/logo.svg');
const GDPRLogo = require('../../assets/images/GDPR-logo.svg');
const masterLogo = require('../../assets/images/master-logo.svg');
const visaLogo = require('../../assets/images/visa-logo.svg');

const Footer = () => {
  const { t } = useTranslation();
  const [integrationModalOpen, toggleIntegrationModal] = useState(false);

  const openModal = useContext(ModalContext);

  return (
    <footer className="container">
      <hr className="footer-hr" />
      <div className="oculisa-footer row">
        <div className="footer-logo col-lg-6 col-sm-4 col-5 col-xl-3 col-md-6">
          <Link to="/">
            <img src={oculisaLogo} alt="aculisa logo" />
          </Link>
          <div className="copyright-text">© 2020 Oculisa. All rights reserved.</div>
        </div>
        <div className="footer-links col-lg-5 d-none d-xl-flex">
          <ul className="list-unstyled">
            <li>
              <Link to="/product">{t('product')}</Link>
            </li>
            <li>
              <button className="link" onClick={() => openModal('privacy')}>
                {t('privacyPolicy')}
              </button>
            </li>
          </ul>
          <ul className="list-unstyled">
            <li>
              <Link to="/pricing">{t('pricing')}</Link>
            </li>
            <li>
              <button className="link" onClick={() => openModal('contact-us')}>
                {t('contactUs')}
              </button>
            </li>
          </ul>
          <ul className="list-unstyled">
            <li>
              <button className="link" onClick={() => openModal('terms')}>
                {t('termsOfService')}
              </button>
            </li>
            <li onClick={() => toggleIntegrationModal(true)}>
              <button className="link">{t('requestAnIntegration')}</button>
            </li>
          </ul>
        </div>
        <div className="footer-credit-cards col-lg-6 col-sm-8 col-7 col-md-6 col-xl-4 text-right">
          <img src={GDPRLogo} alt="GDPR logo" />
          <img src={masterLogo} alt="Master logo" />
          <img src={visaLogo} alt="Visa logo" />
        </div>
      </div>
      {integrationModalOpen && (
        <RequestIntegrationModal
          integrationModalOpen={integrationModalOpen}
          toggleIntegrationModal={() => toggleIntegrationModal(!integrationModalOpen)}
        />
      )}
    </footer>
  );
};

export default Footer;
