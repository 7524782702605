import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';

const oculisaLogo = require('../../assets/images/logo.svg');

const LoginFooter = () => {
  return (
    <footer className="container login-footer">
      <hr className="footer-hr" />
      <div className="oculisa-footer flex-container login-footer">
        <div className="footer-logo flex-container">
          <Link to="/">
            <img src={oculisaLogo} alt="aculisa logo" />
          </Link>
          <div className="login-copyright-text">
            <p>
              © 2020 Oculisa. <br /> All rights reserved.
            </p>
          </div>
        </div>
        <Navbar>
          <Navbar.Brand className="logged-navbar-brand">
            <LanguageSwitcher withIcon={true} />
          </Navbar.Brand>
        </Navbar>
      </div>
    </footer>
  );
};

export default LoginFooter;
