import * as types from './types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH: {
      return { ...action.payload };
    }

    case types.LOG_OUT: {
      return {};
    }

    default:
      return state;
  }
};
