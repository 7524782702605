import axios from 'axios';

// export const setAPI = api => {
// };

const api = 'https://syyvv2oi37.execute-api.eu-central-1.amazonaws.com/stage';
axios.defaults.baseURL = api;

export function getContries() {
  return axios.get('country');
}

export function getLanguages() {
  return axios.get('language');
}

export function requestForIntegration(data) {
  return axios.post('requestForIntegration', data);
}

export function contactUs(data) {
  return axios.post('contactUs', data);
}

export function createOrder({ currency, amount }) {
  return axios.post(api + '/order', { currency, amount: amount * 100, noOfLeads: 1 });
}
