import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import LeadsItemContainer from '../../components/Dashboard/LeadsItemContainer';
import { toast } from 'react-toastify';
import * as leadsApi from 'api/leads';
import { isEmpty } from 'lodash';
import { STATUSES } from './statuses';

class LeadsDashboard extends Component {
  state = {
    leads: [],
    isLoading: false,
  };

  site = this.props.match.params.site;

  componentDidMount() {
    this.getLeads( );
  }

  getLeads = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await leadsApi.getLeads({
        site_domain: this.site.toLowerCase(),
      }, 500);

      this.setState({ leads: response.data.result.items });
    } catch (error) {
      console.dir(error);
      toast.error(error.response.data.message);
    }
    this.setState({ isLoading: false });
  };

  updateLead = async (id, data) => {
    try {
      const response = await leadsApi.updateLead(id, data);

      // TODO: check this logic
      const find = this.state.leads.find((lead) => lead.lead_id === id);
      const index = this.state.leads.indexOf(find);

      this.state.leads[index] = { ...this.state.leads[index], ...data };

      const newLeads = this.state.leads;

      this.setState({ leads: newLeads });
    } catch (error) {
      console.dir(error);
      toast.error(JSON.stringify(error.response.data));
    }
  };

  deleteLead = async (id) => {
    try {
      const response = await leadsApi.deleteLead(id);
      const newLeads = this.state.leads.filter((lead) => lead.lead_id !== id);
      this.setState({ leads: newLeads }, () => {
        toast.success('Lead deleted');
      });
    } catch (error) {
      console.dir(error);
      toast.error(error.response.data.message);
    }
  };

  render() {
    return (
      <div className="container leads-dashboard">
        <div className="site-action-container">
          <p className="site-title">
            <a href={`//${this.site}`} target="_blank">
              {this.site}
            </a>{' '}
            {this.props.t('leadsDashboard')}
            {!this.state.isLoading && (
              <span onClick={this.getLeads} className="icon-container clickable">
                <span className="icon-reload-icon" />
              </span>
            )}
          </p>
        </div>

        {this.state.isLoading ? (
          <div>Loading...</div>
        ) : isEmpty(this.state.leads) ? (
          <div>No leads</div>
        ) : (
          [STATUSES.PENDING, STATUSES.ACTIVE, STATUSES.SALE].map((status) => {
            const d = {
              [STATUSES.PENDING]: 'Pending',
              [STATUSES.ACTIVE]: 'Processing',
              [STATUSES.SALE]: 'Completed',
            };

            // this.props.t(lead.i18nStatusText)

            const leads = this.state.leads.filter((lead) => lead.lead_status.includes(status));

            if (!leads.length) {
              return null;
            }

            return (
              <div className="leads" key={status}>
                <p className="status">{d[status]}</p>
                {leads.map((lead) => (
                  <div className="leads-details-container" key={lead.lead_id}>
                    <LeadsItemContainer
                      lead={lead}
                      updateLead={this.updateLead}
                      deleteLead={this.deleteLead}
                    />
                  </div>
                ))}
              </div>
            );
          })
        )}
      </div>
    );
  }
}

export default withTranslation()(LeadsDashboard);
