import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';
import OculisaTextField from '../Common/OculisaTextField';
import { Button } from 'components/Common';
import { useTranslation, initReactI18next } from 'react-i18next';

const leadsLogo = require('../../assets/images/leads.svg');

function LeadForm({ history }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  function submit(event) {
    event.preventDefault();
    sessionStorage.setItem('registration-email', email);
    history.push('/get-started');
  }
  return (
    <div className="homepage-section leads-form-section">
      <Row>
        <Col md={6} lg={7}>
          <div className="leads-form-box">
            <h3 className="header">{t('readyBoostSales')}</h3>
            <p className="sub-header">{t('getStartedLessMinutes')}</p>
            <Form onSubmit={submit}>
              <div className="form-container flex-container ready-form">
                <OculisaTextField
                  label={t('email')}
                  value={email}
                  autoComplete="email"
                  type="email"
                  onChange={e => setEmail(e)}
                />
                <Button className="get-leads-btn" type="submit">
                  {t('getLeadsFree')}
                </Button>
              </div>
            </Form>
            <div style={{ marginTop: 20 }}>
              <Link to="/login" className="help-btn">
                {t('alreadyHaveAccount')}
              </Link>
            </div>
          </div>
        </Col>
        <Col md={6} lg={5} className="image-wrapper">
          <img src={leadsLogo} className="leads-logo" alt="Expand logo" />
        </Col>
      </Row>
    </div>
  );
}

export default withRouter(LeadForm);
