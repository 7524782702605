import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, initReactI18next } from 'react-i18next';

const exploreDBLogo = require('../../assets/images/explore-database.svg');
const driveLogo = require('../../assets/images/drive.svg');
const scoreLogo = require('../../assets/images/score.svg');

function ExploreSection() {
  const { t } = useTranslation();
  return (
    <div className="explore-section">
      <div className="explore-container flex-container">
        <div className="explore-contents flex-container">
          <img src={exploreDBLogo} alt="Explore DB logo" />
          <h2 className="explore-heading">{t('enrichLeads')}</h2>
          <p className="explore-item-details">{t('exploreEnrich')}</p>
        </div>
        <div className="explore-contents flex-container">
          <img src={scoreLogo} alt="Score logo" />
          <h2 className="explore-heading">{t('scoreLeads')}</h2>
          <p className="explore-item-details">{t('exploreScore')}</p>
        </div>
        <div className="explore-contents flex-container">
          <img src={driveLogo} alt="Drive logo" />
          <h2 className="explore-heading">{t('driveROI')}</h2>
          <p className="explore-item-details">{t('exploreDrive')}</p>
        </div>
      </div>
      <Link to="/product" className="btn purple-button explore-button">
        {t('exploreAll')}
      </Link>
    </div>
  );
}

export default ExploreSection;
