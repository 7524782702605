import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Button as ButtonB } from 'react-bootstrap';

import './Button.scss';

const propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  size: PropTypes.string,
};

const Button = ({ className, to, type, children, onClick, icon, ...props }) => {
  const cls = classNames('button btn purple-button', type, className, icon && 'button-icon');

  if (type === 'submit') {
    return (
      <input
        type="submit"
        className={cls}
        onClick={onClick}
        type={type}
        style={icon && { backgroundImage: `url(${icon})` }}
        {...props}
        value={children}
      />
    );
  }

  if (to) {
    return (
      <Link to={to} className={cls} style={icon && { backgroundImage: `url(${icon})` }} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <ButtonB
      className={cls}
      onClick={onClick}
      type={type}
      style={icon && { backgroundImage: `url(${icon})` }}
      {...props}
    >
      {children}
    </ButtonB>
  );
};

Button.propTypes = propTypes;

export default Button;
