import React, { Component } from 'react';
import LoggedInHeader from '../../components/Header/LoggedInHeader';
import LoggedInFooter from '../../components/Footer/LoggedInFooter';
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

class LoggedInLayout extends Component {
  componentWillUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    // if (!this.props.user.token) {
    //   return <Redirect to="/" />;
    // }

    return (
      <div className="LoggedInLayout">
        <LoggedInHeader location={this.props.location} />
        {this.props.user.token && <div style={{ minHeight: '90vh' }}>{this.props.children}</div>}
        <LoggedInFooter />
      </div>
    );
  }
}

function mapStateToProps({ user }) {
  return {
    user,
  };
}

export default connect(mapStateToProps)(LoggedInLayout);
