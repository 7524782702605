import React, { Fragment } from 'react';
import TextField, { Input } from '@material/react-text-field';

function OculisaTextField({
  type,
  onChange,
  value,
  label,
  autoComplete,
  name,
  style,
  error,
  disabled,
  ...rest
}) {
  function handleChange(event) {
    onChange(event.target.value, name);
  }
  return (
    <Fragment>
      <TextField
        className="oculisa-textfield"
        floatingLabelClassName="oculisa-textfield-floating-label"
        lineRippleClassName="i-dont-need-this"
        style={style}
        label={label}
      >
        <Input
          type={type || 'text'}
          value={value || ''}
          autoComplete={autoComplete}
          onChange={handleChange}
          disabled={disabled}
        />
      </TextField>

      {error ? <div className="oculisa-textfield__error"> {error} </div> : null}
    </Fragment>
  );
}

export default OculisaTextField;
