import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import EditSiteModal from './EditSiteModal';
import SiteCardItem from '../../components/Dashboard/SiteCardItem';
import { withTranslation } from 'react-i18next';
import * as siteApi from 'api/site';
import { ShepherdTourContext } from 'react-shepherd';
import { toast } from 'react-toastify';

const plusIcon = require('../../assets/images/plus-icon.svg');

class Dashboard extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      editModalOpen: false,
      sites: [],
      editWebsiteId: null, // not ok, better page,
      loading: false,
    };

    const { history } = this.props;

    context.addStep({
      attachTo: { element: '.connect-button', on: 'bottom' },
      // title: 'Connect your website',
      buttons: [
        {
          classes: 'button btn btn-secondary',
          text: this.props.t('tutorialButtonExit'),
          action() {
            return this.cancel();
          },
        },
        {
          classes: 'button btn purple-button',
          text: this.props.t('tutorialButtonNext'),
          action() {
            history.push('/create-new-site');
            return this.next();
          },
        },
      ],
      modalOverlayOpeningPadding: 10,
      text: this.props.t('tutorialConnectSiteButton'),
    });

    this.onToggleEditModal = this.onToggleEditModal.bind(this);
  }

  componentDidMount() {
    this.getWebsites();

    try {
      const tutorial = JSON.parse(localStorage.getItem('tutorial'));

      if (!(tutorial && tutorial.dashboard)) {
        this.context.start();
        localStorage.setItem('tutorial', JSON.stringify({ ...tutorial, dashboard: true }));
      }
    } catch (error) {}
  }

  getWebsites = async () => {
    this.setState({ loading: true });
    try {
      const response = await siteApi.getWebsites();
      this.setState({ sites: response.data.result.items });
    } catch (error) {
      console.dir(error);
      toast.error(JSON.stringify(error));
    }
    this.setState({ loading: false });
  };

  onToggleEditModal(id) {
    this.setState({
      editModalOpen: !this.state.editModalOpen,
      editWebsiteId: id,
    });
  }

  render() {
    const { editModalOpen } = this.state;
    return (
      <div className="container dashboard">
        <div className="header flex-container">
          <p className="section-header-text">{this.props.t('dashboardTitle')}</p>
          <Link to="/create-new-site" size="lg" className="btn purple-button connect-button">
            {this.props.t('dashboardConnectButton')}
            <img src={plusIcon} alt="Plus icon" />
          </Link>
        </div>
        <div className="row sites-container">
          {/* {isEmpty(this.state.sites) && (
            <div style={{ textAlign: 'center' }}>
              <p>You don't have sites</p>
            </div>
          )} */}

          {this.state.loading && <div>Loading...</div>}
          {this.state.sites.map((site, i) => {
            const { siteDomain, siteStatus, apiKey } = site;
            return (
              <SiteCardItem
                isTutorial={i === 0}
                key={siteDomain}
                isChecked={siteStatus === 'active'}
                onCheck={async () => {
                  try {
                    const res = await siteApi.updateWebsite(apiKey, {
                      siteStatus: siteStatus === 'active' ? 'inactive' : 'active',
                    });
                    this.getWebsites();
                  } catch (error) {
                    console.dir(error);
                    toast.error(error.response.data.message);
                  }
                }}
                onEdit={() => this.onToggleEditModal(apiKey)}
                site={siteDomain}
              />
            );
          })}
        </div>
        {editModalOpen && (
          <EditSiteModal
            show={editModalOpen}
            handleClose={this.onToggleEditModal}
            id={this.state.editWebsiteId}
          />
        )}
      </div>
    );
  }
}

Dashboard.contextType = ShepherdTourContext;

export default withRouter(withTranslation()(Dashboard));
