import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

const fields = [
  { name: 'visit_date', titleI18n: 'websiteVisitDate' },
  { name: 'visit_end_time', titleI18n: 'analiticsEndVisit' },
  { name: 'request_creation_time', titleI18n: 'analiticsCreationRequest' },
  { name: 'browser_info', titleI18n: 'analiticsBrowserInfo' },
  { name: 'additional_browser_info', titleI18n: 'additionalBrowserInfo' },
  { name: 'device', titleI18n: 'osDevice' },
  { name: 'device_language', titleI18n: 'deviceLanguage' },
  { name: 'mobile_version', titleI18n: 'mobileVersion' },
  { name: 'dimension_on_load', titleI18n: 'screenResolution' },
  { name: 'full_browser_info', titleI18n: 'browserVersion' },
  { name: 'country_of_visitor', titleI18n: 'countryVisitor' },
  { name: 'region', titleI18n: 'subregion' },
  { name: 'time_zone', titleI18n: 'timeZone' },
  { name: 'submitted_page_url', titleI18n: 'submittedPage' },
  { name: 'shopping_cart', titleI18n: 'analiticsShopping' },
  { name: 'title_of_page', titleI18n: 'submittedPageTitle' },
  { name: 'page_history', titleI18n: 'analiticsPagesHistory' },
  { name: 'qualified_website_name', titleI18n: 'websiteName' },
  { name: 'referrer', titleI18n: 'reffer' },
  { name: 'visits_total', titleI18n: 'totalSiteVisits' },
  { name: 'visits_today', titleI18n: 'todaySiteVisits' },
  { name: 'page_visits_today', titleI18n: 'todaySubmittedVisits' },
  { name: 'time_spent_total', titleI18n: 'totalTimeSpent' },
  { name: 'time_spent_today', titleI18n: 'todayTimeSpent' },
  { name: 'page_time_spent_today', titleI18n: 'todaySubmittedTimeSpent' },
  { name: 'HTML_form_id', titleI18n: 'htmlIdForm' },
  { name: 'HTML_form_name', titleI18n: 'htmlNameForm' },
  { name: 'Number_of_inputs', titleI18n: 'numberOfInputs' },
  { name: 'if_the_trusted_zone', titleI18n: 'userTrustZone' },
  { name: 'interaction_events', titleI18n: 'interactionEvents' },
];

const InternalData = ({ lead }) => {
  const { t } = useTranslation();
  const { recommendations } = lead;

  return (
    <Fragment>
      <p className="heading">{t('internalData')}</p>
      <div className="data">
        {fields.map(({ name, titleI18n }) => {
          return (
            <div className="two-row" key={name}>
              <p className="label">{t(titleI18n)}</p>
              <p className="text">
                {lead[name]
                  ? typeof lead[name] === 'object'
                    ? JSON.stringify(lead[name])
                    : lead[name]
                  : '–'}
              </p>
            </div>
          );
        })}
        <div className="two-row highlighted">
          <p className="label">{t('reccomendedProducts')}</p>
          <p className="text">
            {!isEmpty(recommendations) ? JSON.stringify(recommendations) : 'None'}
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default InternalData;
