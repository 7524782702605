import React, { useState } from 'react';
import StatusDropdown from './StatusDropdown';
import classNames from 'classnames';
import { useTranslation, initReactI18next } from 'react-i18next';
import { STATUSES } from '../../containers/Dashboard/statuses';

const StatusSteps = ({ updateLead, status, id }) => {
  const { t } = useTranslation();

  async function update(status) {
    await updateLead({
      lead_status: status,
    });
  }
  return (
    <div className="status-steps">
      <div
        onClick={() => update(STATUSES.PENDING)}
        className={classNames('step clickable', {
          done: status === STATUSES.PENDING,
        })}
      >
        <span className="number">1.</span>
        {t('leadsStatusPending')}
      </div>
      <div className="connector" />
      <div
        onClick={() => update(STATUSES.ACTIVE)}
        className={classNames('step clickable', {
          done: status === STATUSES.ACTIVE,
        })}
      >
        <span className="number">2.</span>
        {t('leadsStatusProcessing')}
      </div>
      <div className="connector" />
      <StatusDropdown step={status} setStep={update} />
    </div>
  );
};

export default StatusSteps;
