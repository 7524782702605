import * as types from './types';

import axios from 'axios';

import Amplify, { Auth } from 'aws-amplify';

let authInterceptor;

export const auth = (token) => async (dispatch) => {
  authInterceptor = axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  const { attributes } = await Auth.currentAuthenticatedUser();

  dispatch({
    type: types.AUTH,
    payload: {
      token: token,
      email: attributes.email,
    },
  });
};

export const logOut = () => {
  axios.interceptors.request.eject(authInterceptor);
  Auth.signOut(); // async maybe
  localStorage.removeItem('token');

  return {
    type: types.LOG_OUT,
  };
};
