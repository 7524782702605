import React, { useEffect, useState } from 'react';
import LeadItemDesktop from './LeadItemDesktop';

const leadsAvatar = require('../../assets/images/leads-avatar.svg');

const LeadsItemContainer = ({ lead, updateLead, deleteLead }) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let component = null;

  // switch (true) {
  //   case width < 481:
  //     component = <h1>Mobile</h1>;
  //     break;

  //   case width > 481 && width < 781:
  //     component = (
  //       <div className="leads-item-container">
  //         <div className="user-details">
  //           <div className="left">
  //             <div className="top">
  //               <div>
  //                 <img className="leads-avatar" src={leadsAvatar} alt="Leads avatar" />
  //               </div>
  //               <div style={{ paddingLeft: 20, flexGrow: 1 }}>
  //                 <div className="two-row">
  //                   <p className="label">Name</p>
  //                   <p className="text">John</p>
  //                 </div>
  //                 <div className="two-row">
  //                   <p className="label">Phone</p>
  //                   <p className="text">+1 636 8080 944</p>
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="bottom probability">
  //               <p className="text">
  //                 Deal probability:{' '}
  //                 <span style={{ fontWeight: 'bold', color: '#623695' }}>86%</span>
  //               </p>
  //               <p className="text padding-left">
  //                 18.04.2019 <span style={{ color: '#8C8C8C' }}>09:54</span>
  //               </p>
  //             </div>
  //           </div>
  //           <div className="right">Right</div>
  //         </div>
  //         <div>Second</div>
  //         <div>Third</div>
  //       </div>
  //     );
  //     break;

  //   default:
  component = <LeadItemDesktop lead={lead} updateLead={updateLead} deleteLead={deleteLead} />;
  //     break;
  // }

  return <div>{component}</div>;
};

export default LeadsItemContainer;
