import React, { Component } from 'react';
import { Form, Row, Col, Dropdown } from 'react-bootstrap';
import OculisaTextField from '../../components/Common/OculisaTextField';
import { withTranslation } from 'react-i18next';

const chevronDown = require('../../assets/images/chevron-down.svg');
const chevronUp = require('../../assets/images/chevron-up.svg');

class MainSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      languageSelectDropdownOpen: false,
    };

    this.onToggleSelectLanguage = this.onToggleSelectLanguage.bind(this);
  }

  onToggleSelectLanguage(isOpen) {
    this.setState({
      languageSelectDropdownOpen: isOpen,
    });
  }

  render() {
    const { name, email, languageSelectDropdownOpen } = this.state;

    return (
      <div className="section-step-form">
        <Form className="sign-up-form">
          <Form.Group as={Row}>
            <Col sm="6">
              <Form.Label>
                {this.props.t('name')}:
                <div>
                  <small>{this.props.t('profileNameDescription')}</small>
                </div>
              </Form.Label>
              <OculisaTextField
                label={this.props.t('name')}
                value={name}
                onChange={e => this.setState({ name: e })}
              />
            </Col>
            <Col sm="6">
              <Form.Label>
                {this.props.t('email')}:
                <div>
                  <small>{this.props.t('profileEmailDescription')}</small>
                </div>
              </Form.Label>
              <OculisaTextField
                label={this.props.t('email')}
                value={email}
                onChange={e => this.setState({ email: e })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={6}>
              <Form.Label>{this.props.t('profileNotification')}:</Form.Label>
              <Dropdown
                drop="down"
                className="language-select-dropdown"
                onToggle={this.onToggleSelectLanguage}
              >
                <Dropdown.Toggle
                  className="take-full-width create-new-dropdown"
                  id="dropdown-basic"
                >
                  {this.props.t('english')}
                  <img
                    src={languageSelectDropdownOpen ? chevronUp : chevronDown}
                    className="chevron-down"
                    alt="Chevron src"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu className="language-dropdown-menu">
                  <Dropdown.Item href="#" className="selected">
                    {this.props.t('english')}
                  </Dropdown.Item>
                  <Dropdown.Item href="#">{this.props.t('russian')}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default withTranslation()(MainSettings);
