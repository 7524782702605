import React, { Component } from 'react';
import { Form, Row, Col, Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import OculisaTextField from '../../components/Common/OculisaTextField';
import LanguageSelectDropdownMenu from '../../components/Dashboard/LanguageSelectDropdownMenu';
import { withTranslation } from 'react-i18next';

import { ShepherdTourContext } from 'react-shepherd';

const businessLogo = require('../../assets/images/business-logo.svg');
const businessWhiteLogo = require('../../assets/images/customer-white.svg');
const shopWhiteLogo = require('../../assets/images/shop-white.svg');
const userLogo = require('../../assets/images/user-logo.svg');
const chevronDown = require('../../assets/images/chevron-down.svg');
const chevronUp = require('../../assets/images/chevron-up.svg');

class BasicSettings extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      clientType: '1',
      confirmPassword: '',
      languageSelectDropdownOpen: false,
      countrySelectDropdownOpen: false,
    };

    if (!this.props.editModal) {
      context.addStep({
        attachTo: { element: '.basic-settings', on: 'bottom' },
        // id: 'sas',
        modalOverlayOpeningPadding: 5,
        buttons: [
          {
            classes: 'button btn btn-secondary',
            text: this.props.t('tutorialButtonExit'),
            action() {
              return this.cancel();
            },
          },
          {
            classes: 'button btn purple-button',
            text: this.props.t('tutorialButtonNext'),
            action() {
              return this.next();
            },
          },
        ],
        text: this.props.t('tutorialBasicSettings'),
      });
    }

    this.onSelectClientType = this.onSelectClientType.bind(this);
    this.onToggleSelectLanguage = this.onToggleSelectLanguage.bind(this);
    this.onToggleSelectCountry = this.onToggleSelectCountry.bind(this);
  }

  onSelectClientType(event, value) {
    event.preventDefault();

    this.handleChange(value, 'targetCustomer');
  }

  onToggleSelectLanguage(isOpen) {
    this.setState({
      languageSelectDropdownOpen: isOpen,
    });
  }

  onToggleSelectCountry(isOpen) {
    this.setState({
      countrySelectDropdownOpen: isOpen,
    });
  }

  handleChange = (value, name) => {
    if (name === 'siteDomain') {
      value = value.replace('http://', '');
      value = value.replace('https://', '');
    }

    this.props.handleChange(value, name);
  };

  handleContries = (type) => {
    return (value, name) => {
      const arr = this.props[type];

      if (value) {
        arr.push(name);
      } else {
        const index = arr.indexOf(name);
        arr.splice(index, 1);
      }

      this.props.handleChange([...arr], type);
    };
  };

  render() {
    const { editModal } = this.props;
    const { languageSelectDropdownOpen, countrySelectDropdownOpen } = this.state;

    return (
      <div
        className={classNames('section-step-form', 'basic-settings', {
          'no-padding': editModal,
        })}
      >
        <Form className="sign-up-form">
          {!editModal && (
            <Form.Group as={Row}>
              <Form.Label column sm="3" className="basicSettings-title">
                {this.props.t('siteDomain')} <span style={{ color: 'red' }}>*</span>:
              </Form.Label>
              <Col sm="9">
                <OculisaTextField
                  label="site.com"
                  value={this.props.siteDomain}
                  name="siteDomain"
                  error={this.props.errors.siteDomain}
                  disabled={this.props.editModal}
                  style={{ width: 250 }}
                  onChange={this.handleChange}
                />
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row}>
            <Form.Label column md="3" className="basicSettings-title">
              {this.props.t('siteClients')} <span style={{ color: 'red' }}>*</span>:
            </Form.Label>
            <Col md="9" className="action-pills-btn-block">
              <button
                onClick={(event) => this.onSelectClientType(event, 'final_customers')}
                className={classNames('action-pills', {
                  active: this.props.targetCustomer === 'final_customers',
                })}
              >
                <img
                  src={
                    this.props.targetCustomer === 'final_customers' ? businessWhiteLogo : userLogo
                  }
                  className="icon"
                  alt="customer icon"
                />
                {this.props.t('siteCustomers')}
              </button>
              <button
                onClick={(event) => this.onSelectClientType(event, 'other_businesses')}
                className={classNames('action-pills', {
                  active: this.props.targetCustomer === 'other_businesses',
                })}
              >
                <img
                  src={
                    this.props.targetCustomer === 'other_businesses' ? shopWhiteLogo : businessLogo
                  }
                  className="icon"
                  alt="other business icon"
                />
                {this.props.t('siteOther')}
              </button>
            </Col>

            <Col md={{ offset: 3, size: 9 }}>
              {this.props.errors.targetCustomer && (
                <div className="error">{this.props.errors.targetCustomer}</div>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={12}>
              <Dropdown
                drop="down"
                className="language-select-dropdown"
                onToggle={this.onToggleSelectLanguage}
              >
                <Dropdown.Toggle
                  className="take-full-width create-new-dropdown basicSetting--label"
                  id="dropdown-basic"
                >
                  {this.props.t('siteSelectLanguage')} <span style={{ color: 'red' }}>*</span>
                  <img
                    src={languageSelectDropdownOpen ? chevronUp : chevronDown}
                    className="chevron-down"
                    alt="Chevron src"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu className="language-dropdown-menu">
                  <LanguageSelectDropdownMenu
                    list={this.props.languages}
                    contries={this.props.supportLanguages}
                    onSelect={this.handleContries('supportLanguages')}
                  />
                </Dropdown.Menu>
              </Dropdown>

              {this.props.errors.supportLanguages && (
                <div className="error">{this.props.errors.supportLanguages}</div>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={12}>
              <Dropdown className="language-select-dropdown">
                <Dropdown.Toggle
                  className="take-full-width create-new-dropdown basicSetting--label"
                  id="dropdown-basic"
                >
                  {this.props.t('siteSelectCountries')} <span style={{ color: 'red' }}>*</span>
                  <img
                    src={countrySelectDropdownOpen ? chevronUp : chevronDown}
                    className="chevron-down"
                    alt="Chevron down src"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu className="language-dropdown-menu">
                  <LanguageSelectDropdownMenu
                    list={this.props.contries}
                    contries={this.props.operatingCountries}
                    onSelect={this.handleContries('operatingCountries')}
                  />
                </Dropdown.Menu>
              </Dropdown>

              {this.props.errors.operatingCountries && (
                <div className="error">{this.props.errors.operatingCountries}</div>
              )}
            </Col>
          </Form.Group>
        </Form>
      </div>
    );
  }
}
BasicSettings.contextType = ShepherdTourContext;

export default withTranslation()(BasicSettings);
