import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation, initReactI18next } from 'react-i18next';

const productBuilding = require('../../assets/images/product-header-img.svg');
const animatingMouseLogo = require('../../assets/images/mouse.svg');

function ProductIntro() {
  const { t } = useTranslation();
  return (
    <section className="banner-section product__header__content">
      <Row>
        <Col className="banner-section-left" sm={12} md={6} lg={6}>
          <h4 className="product-tour-text">{t('productTour')}</h4>
          <h1 className="focus-text">{t('productTitle')}</h1>
          <h4 className="boost-text">{t('productDescription')}</h4>
          <Link to="/get-started" className="btn purple-button purple-button-big product-tour--btn">
            {t('getLeadsFree')}
          </Link>
          <div className="connect-text__block">
            <h5 className="connect-text">{t('сonnectDescription')}</h5>
            <h5 className="connect-text">{t('noCredit')}</h5>
          </div>
        </Col>
        <Col sm={12} md={6} lg={6} className="product-building-wrapper">
          <img src={productBuilding} className="product-building" alt="Product logo" />
        </Col>
      </Row>
      <a href="#deep-analytics" className="animating-mouse">
        <img src={animatingMouseLogo} alt="animating mouse scroll" />
      </a>
    </section>
  );
}

export default ProductIntro;
