import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const fields = [
  { name: 'address', titleI18n: 'identifyAddress' },
  { name: 'job', titleI18n: 'identifyPosition' },
  { name: 'education', titleI18n: 'identifyEducation' },
  { name: 'birthday_date', titleI18n: 'identifyBirthday' },
  { name: 'groups_subscribed', titleI18n: 'identifyGroups' },
  { name: 'gender', titleI18n: 'identifyGender' },
  { name: 'experience', titleI18n: 'identifyExperience' },
  { name: 'marital_status', titleI18n: 'identifyStatus' },
  { name: 'number_of_children', titleI18n: 'identifyChildren' },
  { name: 'twitter_subs', titleI18n: 'twitterSubs' },
  { name: 'interests', titleI18n: 'identifyInterests' },
  { name: 'languages', titleI18n: 'identifyLanguages' },
  { name: 'tweets', titleI18n: 'identifyTweets' },
  { name: 'additional_contacts', titleI18n: 'contactsAdditional' },
];

const ExternalData = ({ lead }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <p className="heading">{t('externalData')}</p>
      <div className="data">
        {fields.map(({ name, titleI18n }) => {
          return (
            <div className="two-row" key={name}>
              <p className="label">{t(titleI18n)}</p>
              <p className="text"> {lead[name]
                  ? typeof lead[name] === 'object'
                    ? JSON.stringify(lead[name])
                    : lead[name]
                  : '–'}</p>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default ExternalData;
