import React, { Component } from 'react';
import MainSettings from './MainSettings';
import ChangePassword from './ChangePassword';
import { withTranslation } from 'react-i18next';

class CreateNewSite extends Component {
  render() {
    return (
      <div className="container create-new-site profile-settings">
        <div className="content">
          <p className="section-header-text">{this.props.t('profileSettings')}</p>
          <div className="row sections-container">
            <div className="col-sm-12 col-md-12 col-lg-3">
              <p className="section-step-title">{this.props.t('profileMainSettings')}</p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-9">
              <MainSettings />
              <div className="save-btn-container save-edit">
                <button className="btn purple-button">{this.props.t('save')}</button>
              </div>
            </div>
          </div>
          <div className="row sections-container">
            <div className="col-sm-12 col-md-12 col-lg-3">
              <p className="section-step-title">{this.props.t('profileChangePasswordTitle')}</p>
              <p className="optional-text">{this.props.t('profileChangePasswordDescription')}</p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-9">
              <ChangePassword />
              <div className="save-btn-container">
                <button className="btn purple-button">
                  {this.props.t('profileChangePasswordButton')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CreateNewSite);
