import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import OculisaTextField from '../../components/Common/OculisaTextField';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      newPassword: '',
      confirmNewPassword: '',
    };
  }

  render() {
    const { password, newPassword, confirmNewPassword } = this.state;

    return (
      <div className="section-step-form">
        <Form className="sign-up-form">
          <Form.Group as={Row}>
            <Col sm="6">
              <Form.Label>{this.props.t('profileOldPass')}:</Form.Label>
              <OculisaTextField
                type="password"
                label={this.props.t('profileOldPassPlaceholder')}
                value={password}
                onChange={e => this.setState({ password: e })}
              />
            </Col>
            <Col>
              <div className="forgot-password-container">
                <Link to="/" className="forgot-passwor-line-btn">
                  {this.props.t('forgotPassword')}
                </Link>
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm="6">
              <Form.Label>{this.props.t('profileNewPass')}:</Form.Label>
              <OculisaTextField
                type="password"
                label={this.props.t('profileNewPassPlaceholder')}
                value={newPassword}
                onChange={e => this.setState({ newPassword: e })}
              />
            </Col>
            <Col sm="6">
              <Form.Label>{this.props.t('profileRepeatPass')}:</Form.Label>
              <OculisaTextField
                type="password"
                label={this.props.t('profileRepeatPassPlaceholder')}
                value={confirmNewPassword}
                onChange={e => this.setState({ confirmNewPassword: e })}
              />
            </Col>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default withTranslation()(ChangePassword);
