import React from 'react';
import Highlight from 'react-highlight';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

const copyStringToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

  toast.success('Copied!');
};

function getCodeSnippet({
  apiKey,
  customForms,
  cartSettings,
  interactiveEvents,
  unTrackedForms,
  trackedForms,
}) {
  return `<script>
  var maConfig = {
      apiKey: '${apiKey}', // note it is enclosed within quotes
      async: true,
      debug: false,
      track: true,
      ${trackedForms ? `formClasses: ${JSON.stringify(trackedForms.split(','))},` : ''}
      ${unTrackedForms ? `ignoreForms: ${JSON.stringify(unTrackedForms.split(','))},` : ''}
      ${interactiveEvents ? `interactionEvents: ${interactiveEvents},` : ''}
      ${
        !isEmpty(
          customForms.filter(({ form, trigger }) => {
            return form && trigger;
          })
        )
          ? `customForms: ${JSON.stringify(
              customForms.filter(({ form, trigger }) => {
                return form && trigger;
              })
            )},`
          : ''
      }
      ${cartSettings ? `customData: ${cartSettings},` : ''}

    };
  (function() {
      var tk = document.createElement('script');
      tk.src = 'https://oculisa.com/analytics.js';
      tk.type = 'text/javascript';
      tk.async = 'true';
      tk.onload = tk.onreadystatechange = function() {
          var rs = this.readyState;
          if (rs && rs != 'complete' && rs != 'loaded') return;
          try { var tracker = new MAnalytics(maConfig) } catch (e) {}
      };
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(tk, s);
  })();
  </script>
`;
}

const CodeSnippet = ({ editModal, website }) => {
  const { t } = useTranslation();

  const codeSnippet = getCodeSnippet(website);
  return (
    <div
      className={classNames('section-step-form code-snippet', {
        'no-padding': editModal,
      })}
    >
      <p className="form-label">{t('techInstallBeforeTitle')}</p>
      <div className="code-snippet-container">
        <Highlight language="javascript">{codeSnippet}</Highlight>
      </div>
      <div style={{ marginTop: 15 }}>
        <span onClick={() => copyStringToClipboard(codeSnippet)} className="link clickable">
          {t('copyCode')}
        </span>
        {/* <span style={{ paddingLeft: 34 }} className="link clickable">
          {t('sendCodeAndInstructionsOnEmail')}
        </span> */}
      </div>
    </div>
  );
};

export default CodeSnippet;
